<template>

  <q-dialog v-model="confirm" persistent>
    <q-card>
      <q-card-section class="row items-center">
        <q-avatar icon="delete" color="negative" text-color="white" />
        <span class="q-ml-sm">Are you sure?</span>
      </q-card-section>

      <q-card-actions align="right">
        <q-btn flat label="Cancel" color="negative" v-close-popup />
        <q-btn flat label="Delete" color="positive" v-close-popup @click="removeSelectedItems" />
      </q-card-actions>
    </q-card>
  </q-dialog>

  <div class="q-pa-md q-gutter-sm">
    <q-breadcrumbs>
      <q-breadcrumbs-el icon="home" to="/" />
      <q-breadcrumbs-el label="Templates" icon="code"  />
      <q-breadcrumbs-el label="SMS Templates" icon="sms" to="/templates-sms" />
      <template v-slot:separator>
        <q-icon
          size="1.5em"
          name="chevron_right"
          color="primary"
        />
      </template>
    </q-breadcrumbs>
  </div>

  <div class="q-pa-md">
    <q-table
      title = "SMS Templates"
      :rows = "smsTemplatesItems"
      :columns = "columns"
      row-key = "id"
      :sort-by="'id'"
      selection = "multiple"
      v-model:selected = "selected"
      :loading = "loading"
      :pagination="{ rowsPerPage: 20 }"
      color = "primary"
      :filter="filter"
    >

      <template v-slot:body-cell-actions="scope">
        <q-td :props="scope">
          <q-btn flat round icon="difference" color="primary" :to="'/templates-sms-duplicate/' + scope.row.id">
            <q-tooltip class="bg-primary">Duplicate this template</q-tooltip>
          </q-btn>
          <q-btn flat round icon="edit" color="positive" :to="'/templates-sms-edit/' + scope.row.id">
            <q-tooltip class="bg-positive">Edit this template</q-tooltip>
          </q-btn>
          <q-btn flat round icon="delete" color="negative" @click=removeItem(scope.row.id)>
            <q-tooltip class="bg-negative">Delete this template</q-tooltip>
          </q-btn>
        </q-td>
        <q-space /><br >
      </template>
      
      <template v-slot:top-left>
        <q-btn color="negative" icon="delete" :disable="this.selected.length == 0" label="Remove selected" @click="confirm = true" />
        <q-btn color="positive" icon="add" class="q-ml-lg" label="Add template" to="/templates-sms-add" />
        <q-btn
          class="q-ml-lg"
          color="altmedia-blue"
          icon="archive"
          label="Export"
          no-caps
          @click="exportTableCsv"
        />
        <q-space />
      </template>

      <template v-slot:top-right>
        <q-input  dense debounce="300" color="primary" v-model="filter">
          <template v-slot:append>
            <q-icon name="search" />
          </template>
        </q-input>
      </template>

    </q-table>
  </div>
</template>
  
<script>
  import { getRestEndpoint, exportTable } from '@/utils/utils'
  import { handleClientTokenExpiration, handleTokenExpiration } from '@/utils/auth';
  import axios from "axios";
  import { ref } from 'vue'

  export default {
    name: 'SmsTemplates',
    data() {
      return {
        REST_ENDPOINT: '',
        loading: false,
        filter: '',
        selected: [],
        smsTemplatesItems: [],
        columns: [
          { name: 'id', align: 'left', label: 'ID', field: 'id', sortable: true, style: 'width: 70px;'},
          { name: 'name', align: 'left', label: 'Name', field: 'name', sortable: true, style: 'max-width: 80px; white-space: nowrap; overflow: hidden; text-overflow: ellipsis;',},
          { name: 'content', align: 'left', label: 'Content', field: 'content', sortable: true, style: 'max-width: 150px; white-space: nowrap; overflow: hidden; text-overflow: ellipsis;',},
          { name: 'actions', align: 'center', label: 'Actions', sortable: false, style: 'width: 200px', },
        ]
      }
    },
    created() {
      handleClientTokenExpiration(this.$router);
    },
    methods: {
      exportTableCsv() {
        const columnsWithoutActions = [...this.columns];
        columnsWithoutActions.pop();
        exportTable(columnsWithoutActions, this.smsTemplatesItems, 'sms_templates_')
      },
      readDataFromAPI() {
        this.loading = true;
        axios
          .get(`${this.REST_ENDPOINT}sms-template`, { withCredentials: true })
          .then((response) => {
            this.loading = false;
            this.smsTemplatesItems = response.data.sort((a, b) => b.id - a.id);
          })
          .catch((error) => {
            if (error.response && error.response.status === 401 && error.response.data === 'Token expired') {
              handleTokenExpiration(this.$router);
            }
          });
      },
      removeItem(id) {
        if (confirm('Are you sure?')) {
          axios
            .delete(`${this.REST_ENDPOINT}sms-template/${id}`, { withCredentials: true })
            .then(() => {
              this.smsTemplatesItems = this.smsTemplatesItems.filter((item) => item.id !== id);
            })
            .catch((error) => {
              if (error.response && error.response.status === 401 && error.response.data === 'Token expired') {
                handleTokenExpiration(this.$router);
              }
            });
        }
      },

      removeSelectedItems() {
        const deletePromises = this.selected.reduce((promiseChain, item) => {
            return promiseChain.then(() => {
                return axios.delete(`${this.REST_ENDPOINT}sms-template/${item.id}`, { withCredentials: true });
            }).catch((error) => {
                if (error.response && error.response.status === 401 && error.response.data === 'Token expired') {
                    handleTokenExpiration(this.$router);
                }
            });
        }, Promise.resolve()); 

        deletePromises.then(() => {
            this.selected = [];
            this.readDataFromAPI();
        });
      }
    },
    mounted() {
      this.REST_ENDPOINT = getRestEndpoint();
      this.readDataFromAPI();
    },
    setup () {
      return {
        confirm: ref(false),
      }
    }
  }
</script>
  
<style lang="scss">
  
</style>