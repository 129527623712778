<template>
  <div class="q-pa-md q-gutter-sm">
    <q-breadcrumbs>
      <q-breadcrumbs-el icon="home" to="/" />
      <q-breadcrumbs-el label="Sender" icon="phone" to="/sender" />
      <q-breadcrumbs-el label="Request New Sender" icon="add" />
      <template v-slot:separator>
        <q-icon size="1.5em" name="chevron_right" color="primary" />
      </template>
    </q-breadcrumbs>
  </div>

  <q-card class="sms-card">
    <q-form @submit.prevent="submitForm" class="qForm">
      <q-card-section>
        <div class="text-h6">Request New Sender</div>
      </q-card-section>

      <q-separator inset />

      <q-card-section class="column q-gutter-md">

        <q-input v-model.trim="form.name" label="Name *" />
        <span class="error-message">{{ formErrors.name }}</span>

        <q-select
          color="primary"
          outlined
          v-model="selectedOptionsCountries"
          multiple
          :options="optionsCountries"
          use-chips
          stack-label
          label="Country *"
          option-label="name" 
        />
        <span class="error-message">{{ formErrors.selectedOptionsCountries }}</span>

      </q-card-section>

      <q-card-actions>
        <q-btn icon="add" color="altmedia-blue" label="Request New" @click="submitForm()" />
      </q-card-actions>

      <span
        class="msg-response"
        :class="{ success: sendResponseSuccess, error: !sendResponseSuccess }"
      >{{ sendResponse }}</span>
    </q-form>
  </q-card>
</template>

<script>
import { getRestEndpoint } from '@/utils/utils'
import { handleClientTokenExpiration, handleTokenExpiration } from '@/utils/auth'
import axios from 'axios'

export default {
  name: 'SmsCampaignBuilder',

  data() {
    return {
      form: {
        name: '',
      },
      
      formErrors: {
        name: '',
      },
      sendResponse: '',
      sendResponseSuccess: null,
      REST_ENDPOINT: '',
      selectedOptionsCountries: [],
      optionsCountries: [],
    }
  },
  created() {
    handleClientTokenExpiration(this.$router)
  },
  methods: {
    async submitForm() {
      this.sendResponse = '';
      this.validateForm();

      if (this.isFormValid()) {
        try {
          const formData = {
            name: this.form.name,
            selectedOptionsCountries: this.selectedOptionsCountries.map(country => country.id),
          };

          await axios.post(`${this.REST_ENDPOINT}sender`, formData, { withCredentials: true });
          this.$router.push('/sender');
        } catch (error) {
          if (error.response && error.response.status === 401 && error.response.data === 'Token expired') {
            handleTokenExpiration(this.$router);
          } else if (error.response && error.response.status === 400 && error.response.data && error.response.data.error) {
            this.formErrors.name = error.response.data.error;
          }
        }
      }
    },

    
    validateForm() {
      if (!this.form.name) {
        this.formErrors.name = 'Name field is required';
      } else if (this.form.name.length > 12) {
        this.formErrors.name = 'Name must be a maximum of 12 alphanumeric characters';
      } else {
        this.formErrors.name = '';
      }

      if (this.selectedOptionsCountries.length === 0) {
        this.formErrors.selectedOptionsCountries = 'At least one country must be selected';
      } else if (this.selectedOptionsCountries.length > 10) {
        this.formErrors.selectedOptionsCountries = 'Maximum of 10 countries can be selected';
      } else {
        this.formErrors.selectedOptionsCountries = '';
      }
    },

    isFormValid() {
      return Object.values(this.formErrors).every((error) => !error)
    },
    charCount() {
      this.totalChar = this.form.message.length
    },
    
    getCountries() {
      axios
        .get(`${this.REST_ENDPOINT}utils/countries`, { withCredentials: true })
        .then((response) => {
          this.optionsCountries = response.data
        })
        .catch((error) => {
          if (
            error.response &&
            error.response.status === 401 &&
            error.response.data === 'Token expired'
          ) {
            handleTokenExpiration(this.$router);
          }
        });
    },

  },
  mounted() {
    this.REST_ENDPOINT = getRestEndpoint()
    this.getCountries()
  },
}
</script>

<style lang="scss">
.sms-card {
  width: 812px !important;
  margin: 10px 20px;
}

.msg-response {
  width: 100%;
  display: block;
  margin-top: 25px;
  margin-left: 10px;
  font-size: 1rem;
}

.msg-response.success {
  color: green;
}
.msg-response.error {
  color: red;
}

.label-date-time {
  width: 30%;
}

.el-date-time {
  width: 65%;
}
</style>
