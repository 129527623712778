import { createStore } from 'vuex';
import createPersistedState from 'vuex-persistedstate';

export default createStore({
  state: {
    isExpansionReportsOpen: false,
    isExpansionBillingOpen: false,
    isExpansionShortUrlOpen: false,
    isExpansionSmsOpen: false,
    username: '',
    isLoggedIn: false
  },
  plugins: [
    createPersistedState()
  ],
  mutations: {
    toggleExpansionReports(state, payload) {
      state.isExpansionReportsOpen = payload;
    },
    toggleExpansionBilling(state, payload) {
      state.isExpansionBillingOpen = payload;
    },
    toggleExpansionShortUrl(state, payload) {
      state.isExpansionShortUrlOpen = payload;
    },
    toggleExpansionSms(state, payload) {
      state.isExpansionSmsOpen = payload;
    },
    setUsername(state, payload) {
      state.username = payload;
    },
    toggleLogin(state, payload) {
      state.isLoggedIn = payload;
    },
  },
  actions: {},
  getters: {},
});
