<template>
  <div class="q-pa-md q-gutter-sm">
    <q-breadcrumbs>
      <q-breadcrumbs-el icon="home" to="/" />
      <q-breadcrumbs-el label="SMS" icon="code" />
      <q-breadcrumbs-el label="Campaign Builder" icon="campaign" />
      <template v-slot:separator>
        <q-icon size="1.5em" name="chevron_right" color="primary" />
      </template>
    </q-breadcrumbs>
  </div>

  <q-card class="sms-card">
    <q-form @submit.prevent="submitForm" class="qForm">
      <q-card-section>
        <div class="text-h6">Campaign Builder</div>
      </q-card-section>

      <q-separator inset />

      <q-card-section class="column q-gutter-md">

        <q-input v-model.trim="form.name" label="Name *" />
        <span class="error-message">{{ formErrors.name }}</span>

        <q-select
          color="primary"
          outlined
          v-model="selectedOptionsContactGroups"
          multiple
          :options="optionsContactGroups"
          use-chips
          stack-label
          label="Contact Groups *"
        />
        <span class="error-message">{{ formErrors.selectedOptionsContactGroups }}</span>

        <q-select 
          outlined
          v-model = "selectedOptionsSender"
          :options = "optionsSenders"
          label = "Sender ID"
        />
        <span class="error-message">{{ formErrors.sender }}</span>

        <q-select
          outlined
          v-model="selectedOptionSmsTemplates"
          :options="optionsSmsTemplates"
          label="SMS Template"
          emit-value
          map-options
          @update:model-value="populateTextarea"
        />

        <q-checkbox
          v-model="showShortUrls"
          label="Show Short URLs"
          color="primary"
        />

        <div v-if="showShortUrls && shortUrlLinksItems.length > 0" class="short-url-list">
          <div v-for="item in shortUrlLinksItems" :key="item.id" class="short-url-item">
            <div class="short-url">
              <span class="short-url-text">{{ item.fulllink }}</span>
            </div>
            <div class="full-url">
              <span class="full-url-text">{{ item.target_url }}</span>
            </div>

            <q-btn 
              icon="content_copy" 
              color="positive" 
              @click="copyToClipboard(item.fulllink)"
              size="sm"
              class="copy-btn"
            >
              <q-tooltip class="bg-positive">Copy Url</q-tooltip>
            </q-btn>
            
          </div>
        </div>

        <q-input @keyup="charCount" type="textarea" v-model.trim="form.message" label="Message *" />
        <span class="">{{ totalCharWarning }}</span>
        <span class="error-message">{{ formErrors.message }}</span>

      </q-card-section>

      <q-item>
        <q-item-section>
          <q-checkbox v-model="form.scheduleCampaign" label="Schedule Campaign?" />
        </q-item-section>
      </q-item>

      <q-item v-if="form.scheduleCampaign">
        <q-item-section class="label-date-time">
          <q-item-label>Date / time</q-item-label>
        </q-item-section>
        <q-item-section class="el-date-time">
          <q-input filled v-model="form.startDate" class="startDate">
            <template v-slot:prepend>
              <q-icon name="event" class="cursor-pointer">
                <q-popup-proxy cover transition-show="scale" transition-hide="scale">
                  <q-date v-model="form.startDate" mask="YYYY-MM-DD HH:mm">
                    <div class="row items-center justify-end">
                      <q-btn v-close-popup label="Close" color="primary" flat />
                    </div>
                  </q-date>
                </q-popup-proxy>
              </q-icon>
            </template>

            <template v-slot:append>
              <q-icon name="access_time" class="cursor-pointer">
                <q-popup-proxy cover transition-show="scale" transition-hide="scale">
                  <q-time v-model="form.startDate" mask="YYYY-MM-DD HH:mm" format24h>
                    <div class="row items-center justify-end">
                      <q-btn v-close-popup label="Close" color="primary" flat />
                    </div>
                  </q-time>
                </q-popup-proxy>
              </q-icon>
            </template>
          </q-input>
        </q-item-section>
      </q-item>
      <span class="error-message">{{ formErrors.startDate }}</span>


      <q-item v-if="form.scheduleCampaign">
        <q-item-section>
          <q-select
            v-model="form.frequency"
            label="Frequency"
            :options="frequencyOptions"
            stack-label
            emit-value
            map-options
          />
        </q-item-section>
      </q-item>

      <!-- <q-item v-if="form.scheduleCampaign && form.frequency === 'custom'">
        <q-item-section>
          <q-input v-model="form.frequencyAmount" type="number" label="Frequency Amount" />
        </q-item-section>
        <q-item-section>
          <q-select
            v-model="form.frequencyUnit"
            label="Frequency Unit"
            :options="frequencyUnitOptions"
            stack-label
            emit-value
            map-options
          />
        </q-item-section>
      </q-item>
      <span class="error-message">{{ formErrors.frequencyAmount }}</span>
      <span class="error-message">{{ formErrors.frequencyUnit }}</span> -->

      <q-item v-if="form.scheduleCampaign && form.frequency !== 'one-time'">
        <q-item-section class="label-date-time">
          <q-item-label>End date / time</q-item-label>
        </q-item-section>
        <q-item-section class="el-date-time">
          <q-input filled v-model="form.endDate" class="endDate">
            <template v-slot:prepend>
              <q-icon name="event" class="cursor-pointer">
                <q-popup-proxy cover transition-show="scale" transition-hide="scale">
                  <q-date v-model="form.endDate" mask="YYYY-MM-DD HH:mm">
                    <div class="row items-center justify-end">
                      <q-btn v-close-popup label="Close" color="primary" flat />
                    </div>
                  </q-date>
                </q-popup-proxy>
              </q-icon>
            </template>

            <template v-slot:append>
              <q-icon name="access_time" class="cursor-pointer">
                <q-popup-proxy cover transition-show="scale" transition-hide="scale">
                  <q-time v-model="form.endDate" mask="YYYY-MM-DD HH:mm" format24h>
                    <div class="row items-center justify-end">
                      <q-btn v-close-popup label="Close" color="primary" flat />
                    </div>
                  </q-time>
                </q-popup-proxy>
              </q-icon>
            </template>
          </q-input>
        </q-item-section>
      </q-item>
      <span class="error-message">{{ formErrors.endDate }}</span>



      <q-item v-if="form.scheduleCampaign && form.frequency !== 'one-time'">
        <q-item-section class="label-maxrun">
          <q-item-label>Maximum run time</q-item-label>
        </q-item-section>
        <q-item-section>
          <q-input v-model="form.maxRunTime" type="number" label="Maximum run time" :max="10" />
        </q-item-section>
        
      </q-item>
      <span class="error-message">{{ formErrors.maxRunTime }}</span>
      


      <q-card-actions>
        <q-btn icon="send" color="altmedia-blue" label="Send" @click="submitForm()" />
      </q-card-actions>

      <span
        class="msg-response"
        :class="{ success: sendResponseSuccess, error: !sendResponseSuccess }"
      >{{ sendResponse }}</span>
    </q-form>
  </q-card>
</template>

<script>
import { getRestEndpoint, calculateSmsLengthAndCount } from '@/utils/utils'
import { handleClientTokenExpiration, handleTokenExpiration } from '@/utils/auth'
import axios from 'axios'
import moment from 'moment';

export default {
  name: 'SmsCampaignBuilder',

  data() {
    return {
      form: {
        name: '',
        sender: '',
        message: '',
        startDate: '',
        endDate: '',
        scheduleCampaign: false,
        frequency: 'one-time',
        maxRunTime: 0,
      },
      frequencyOptions: [
        { label: 'One Time', value: 'one-time' },
        { label: 'Daily', value: 'daily' },
        { label: 'Monthly', value: 'monthly' },
        { label: 'Yearly', value: 'yearly' },
        // { label: 'Custom', value: 'custom' },
      ],
      formErrors: {
        name: '',
        sender: '',
        message: '',
        startDate: '',
        endDate: '',
        maxRunTime: ''
      },
      sendResponse: '',
      sendResponseSuccess: null,
      REST_ENDPOINT: '',
      selectedOptionSmsTemplates: null,
      selectedOptionsContactGroups: [],
      selectedOptionsSender: null,
      totalCharWarning: null,
      optionsSmsTemplates: [],
      optionsContactGroups: [],
      optionsSenders: [],
      shortUrlLinksItems: [],
      showShortUrls: false 
    }
  },
  created() {
    handleClientTokenExpiration(this.$router)
  },
  methods: {
    async submitForm() {
      this.sendResponse = '';
      this.validateForm();
      
      if (this.isFormValid()) {
        try {
          const formData = {
            name: this.form.name,
            message: this.form.message,
            schedule: this.form.scheduleCampaign,
            startDatetime: this.form.startDate,
            endDatetime: this.form.endDate !== '' ? this.form.endDate : null, 
            frequency: this.form.frequency,
            maxRunTime: this.form.maxRunTime,
            selectedOptionsContactGroups: this.selectedOptionsContactGroups,
            selectedOptionsSender: this.selectedOptionsSender
          };

          await axios.post(`${this.REST_ENDPOINT}campaign`, formData, { withCredentials: true });
          this.$router.push('/reports-campaigns');
        } catch (error) {
          if (error.response && error.response.status === 401 && error.response.data === 'Token expired') {
            handleTokenExpiration(this.$router);
          }
        }
      }
    },

    validateForm() {
      if (!this.form.name) {
        this.formErrors.name = 'Name field is required';
      } else {
        this.formErrors.name = '';
      }

      if (this.selectedOptionsContactGroups.length === 0) {
        this.formErrors.selectedOptionsContactGroups = 'At least one contact group must be selected';
      } else {
        this.formErrors.selectedOptionsContactGroups = '';
      }

      if (!this.selectedOptionsSender) { 
        this.formErrors.sender = 'Sender ID field is required';
      } else {
        this.formErrors.sender = '';
      }

      if (!this.form.message) {
        this.formErrors.message = 'Message field is required';
      } else {
        this.formErrors.message = '';
      }

      if (this.form.scheduleCampaign) {
        if (!this.form.startDate) {
          this.formErrors.startDate = 'Start date field is required';
        } else if (moment(this.form.startDate) <= moment()) {
          this.formErrors.startDate = 'Start date must be after the current date';
        } else {
          this.formErrors.startDate = '';
        }

        if (this.form.frequency !== 'one-time') {
          if (this.form.maxRunTime === 0) {
            this.formErrors.maxRunTime = 'Maximum run time field is required';
          } else {
            this.formErrors.maxRunTime = '';
          }

          if (!this.form.endDate) {
            this.formErrors.endDate = 'End date field is required';
          } else if (moment(this.form.endDate) <= moment(this.form.startDate)) {
            this.formErrors.endDate = 'End date must be after the start date';
          } else {
            const startDate = moment(this.form.startDate);
            const endDate = moment(this.form.endDate);
            const maxRunTime = this.form.maxRunTime;

            if (this.form.frequency === 'daily') {
              const nextDate = startDate.clone().add(maxRunTime - 1, 'days');
              if (nextDate.isAfter(endDate)) {
                this.formErrors.endDate = `End date must be at least ${maxRunTime} days after the start date`;
              } else {
                this.formErrors.endDate = '';
              }
            } else if (this.form.frequency === 'monthly') {
              const nextDate = startDate.clone().add(maxRunTime, 'months').subtract(1, 'months');
              if (nextDate.isAfter(endDate)) {
                this.formErrors.endDate = `End date must be at least ${maxRunTime} months after the start date`;
              } else {
                this.formErrors.endDate = '';
              }
            } else if (this.form.frequency === 'yearly') {
              const nextDate = startDate.clone().add(maxRunTime, 'years').subtract(1, 'years');
              if (nextDate.isAfter(endDate)) {
                this.formErrors.endDate = `End date must be at least ${maxRunTime} years after the start date`;
              } else {
                this.formErrors.endDate = '';
              }
            }
          }
        } else {
          this.formErrors.maxRunTime = '';
          this.formErrors.endDate = '';
        }
      }
    },
    isFormValid() {
      return Object.values(this.formErrors).every((error) => !error)
    },
    charCount: function() {
      this.totalCharWarning = calculateSmsLengthAndCount(this.form.message); 
      if (this.form.message.length > 1000) {
        this.form.message = this.form.message.substring(0, 1000);
      }
      this.totalChar = 1000 - this.form.message.length;
    },
    getSmsTemplates() {
      axios
        .get(`${this.REST_ENDPOINT}sms-template`, { withCredentials: true })
        .then((response) => {
          this.optionsSmsTemplates = response.data.map((item) => ({
            label: item.name,
            value: item.content,
          }))
        })
        .catch((error) => {
          if (
            error.response &&
            error.response.status === 401 &&
            error.response.data === 'Token expired'
          ) {
            handleTokenExpiration(this.$router)
          }
        })
    },
    getSenders() {
      axios
        .get(`${this.REST_ENDPOINT}sender/active-senders`, { withCredentials: true })
        .then((response) => {
          this.optionsSenders = response.data.map((item) => ({
            label: item.sender_name,
            value: item.sender_id,
          }));
        })
        .catch((error) => {
          if (error.response && error.response.status === 401 && error.response.data === 'Token expired') {
            handleTokenExpiration(this.$router);
          }
        });
    },
    getShortUrls() {
      this.loading = true;
      axios.get(`${this.REST_ENDPOINT}short-url-links`, { withCredentials: true })
        .then((response) => {
          this.loading = false;
          this.shortUrlLinksItems = response.data
            .filter(item => item.disabled !== 1)
            .map(item => ({
              ...item,
              fulllink: `${item.domain_name}/${item.link}`,
            }))
            .sort((a, b) => b.id - a.id);
        })
        .catch((error) => {
          this.loading = false;
          if (error.response && error.response.status === 401 && error.response.data === 'Token expired') {
            handleTokenExpiration(this.$router);
          }
        });
    },
    getContactGroups() {
      axios
        .get(`${this.REST_ENDPOINT}contact-groups`, { withCredentials: true })
        .then((response) => {
          const filteredData = response.data.filter((item) => item.num_contacts > 0);
          this.optionsContactGroups = filteredData.map((item) => ({
            label: item.name + ' (' + item.num_contacts + ' Contacts)',
            value: item.id,
          }));
        })
        .catch((error) => {
          if (
            error.response &&
            error.response.status === 401 &&
            error.response.data === 'Token expired'
          ) {
            handleTokenExpiration(this.$router);
          }
        });
    },

    populateTextarea() {
      this.form.message = this.selectedOptionSmsTemplates
      this.charCount()
    },
    copyToClipboard(text) {
      navigator.clipboard.writeText(text);
    }
  },
  mounted() {
    this.REST_ENDPOINT = getRestEndpoint()
    this.getSmsTemplates();
    this.getContactGroups();
    this.getSenders();
    this.getShortUrls();
  },
}
</script>

<style lang="scss" scoped>
  .sms-card {
      width: 98% !important;
      margin: 10px 20px;
    }

  .msg-response {
    width: 100%;
    display: block;
    margin-top: 25px;
    margin-left: 10px;
    font-size: 1rem;
  }

  .msg-response.success {
    color: green;
  }
  .msg-response.error {
    color: red;
  }

  .short-url-list {
    width: 100%;
    margin-top: 10px;
  }

  .short-url-item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 10px;
  }

  .short-url {
    flex: 0 0 250px;
    padding: 5px 0;
    margin: 5px 0;
    font-weight: bold;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .full-url {
    flex: 1;
    padding: 5px 0;
    margin: 5px 0 5px 10px;
    color: #999;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .copy-btn {
    margin-left: 10px;
  }

  .label-date-time {
    width: 30%;
  }

  .el-date-time {
    width: 65%;
  }
</style>
