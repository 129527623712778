<template>

  <q-dialog v-model="confirm" persistent>
    <q-card>
      <q-card-section class="row items-center">
        <q-avatar icon="logout" color="primary" text-color="white" />
        <span class="q-ml-sm">Are you sure?</span>
      </q-card-section>

      <q-card-actions align="right">
        <q-btn flat label="Cancel" color="negative" v-close-popup />
        <q-btn flat label="Logout" color="purple" v-close-popup @click="userLogout" />
      </q-card-actions>
    </q-card>
  </q-dialog>
  
  <q-layout view="lHh Lpr lFf" class="shadow-2 rounded-borders">
    <q-header class="appHeader" bordered v-if="!['login-user', 'register-user', 'password-user', 'verify-email'].includes($route.name)">
      <q-toolbar>
        <q-btn
          flat
          dense
          round
          @click="leftDrawerOpen = !leftDrawerOpen"
          aria-label="Menu"
          icon="menu"
        />
      
        <div class="userInfo">

          <ul class="buttons">
            <li class="pricing">
              <span>
                <a href="#" class="link-pricing" @click="$router.replace('/pricing')" icon-right="price_change">Pricing</a>
              </span>
            </li>
            <!-- <li>payment type {{ paymentType }}</li> -->
            <li class="balance">
              <span>
                <span>{{ balanceLabel }}: {{ balance }} EUR</span>
              </span>
            </li>

            <li class="welcome" v-if="isLoggedIn">
              <span>
                User: <span class="user">{{username}}</span> 
              </span>
            </li>

            <li class="welcome" v-if="isLoggedIn">
              <span>Product: </span>
              <div class="custom-dropdown">
                <div class="dropdown-label" @click="toggleDropdown">
                  {{ selProductDescr }} <span class="arrow">▼</span>
                </div>
                <ul class="dropdown-menu" v-if="dropdownOpen">
                  <li 
                    v-for="product in productIds" 
                    :key="product.id" 
                    @click="selectProduct(product.id)" 
                    :class="{ selected: product.id === selProductId }"
                  >
                    {{ product.descr }}
                  </li>
                </ul>
              </div>
            </li>

            <li class="user">
              <span class="profile-dropdown">
                Profile <span class="arrow">▼</span>
                <ul class="dropdown-menu">
                  <li>
                    <router-link :to="{ path: '/password', query: { mode: 'change' } }">Change Password</router-link>
                  </li>
                  <li>
                    <router-link :to="{ path: '/user-profile-view' }">User profile</router-link>
                  </li>
                  <li>
                    <router-link :to="{ path: '/apikey' }">API key</router-link>
                  </li>
                  <li>
                    <router-link :to="{ path: '/docs', }">Docs</router-link>
                  </li>
                  <li v-if="isLoggedIn">
                    <a href="#" @click="confirm = true" icon-right="logout">Logout</a>
                  </li>
                </ul>
              </span>
            </li>

          </ul>

        </div>
      </q-toolbar>
    </q-header>

    <q-drawer v-if="!['login-user', 'register-user', 'password-user', 'verify-email'].includes($route.name)"
      v-model="leftDrawerOpen"
      show-if-above
      class="bg-white-2"
    >
      
      <q-list class="rounded-borders appList">

        <q-item-label header class="img-logo-header">
          <img class="img-logo-dash" src="./assets/altmedia-dash.png" alt="">
        </q-item-label>
        
        <q-item active-class="tab-active" clickable tag="a" to="/">
          <q-item-section avatar>
            <q-icon name="home" />
          </q-item-section>
          <q-item-section>
            <q-item-label>Dashboard</q-item-label>
          </q-item-section>
        </q-item>
       
        <q-expansion-item  v-model="$store.state.isExpansionReportsOpen" :content-inset-level="0.5" icon="code" label="Reports">

          <q-item active-class="tab-active" clickable tag="a" to="/reports-campaigns">
          <q-item-section avatar>
            <q-icon name="campaign" />
          </q-item-section>
          <q-item-section>
            <q-item-label>Campaigns</q-item-label>
          </q-item-section>
        </q-item>

          <q-item active-class="tab-active" clickable tag="a" to="/reports-edr">
          <q-item-section avatar>
            <q-icon name="receipt" />
          </q-item-section>
          <q-item-section>
            <q-item-label>EDR</q-item-label>
            <q-item-label caption>SMS detailed records</q-item-label>
          </q-item-section>
        </q-item>
        <q-item active-class="tab-active" clickable tag="a" to="/reports-sms-usage-stats">
          <q-item-section avatar>
            <q-icon name="sms" />
          </q-item-section>
          <q-item-section>
            <q-item-label>SMS Usage</q-item-label>
          </q-item-section>
        </q-item>
      </q-expansion-item>

      <q-expansion-item
        v-if="paymentType === 'prepay'"
        v-model="$store.state.isExpansionBillingOpen"
        :content-inset-level="0.5"
        icon="receipt_long"
        label="Billing"
      >
        <q-item active-class="tab-active" clickable tag="a" to="/invoices">
          <q-item-section avatar>
            <q-icon name="calculate" />
          </q-item-section>
          <q-item-section>
            <q-item-label>Invoices</q-item-label>
          </q-item-section>
        </q-item>

        <q-item active-class="tab-active" clickable tag="a" to="/payments">
          <q-item-section avatar>
            <q-icon name="local_atm" />
          </q-item-section>
          <q-item-section>
            <q-item-label>Payments</q-item-label>
          </q-item-section>
        </q-item>
      </q-expansion-item>

      <q-item active-class="tab-active" clickable tag="a" to="/sender">
          <q-item-section avatar>
            <q-icon name="phone" />
          </q-item-section>
          <q-item-section>
            <q-item-label>Sender ID</q-item-label>
          </q-item-section>
      </q-item>





      <q-expansion-item v-model="$store.state.isExpansionShortUrlOpen" :content-inset-level="0.5" icon="code" label="Short URL">
          <q-item active-class="tab-active" clickable tag="a" to="/short-url-links">
          <q-item-section avatar>
            <q-icon name="link" />
          </q-item-section>
          <q-item-section>
            <q-item-label>Links</q-item-label>
          </q-item-section>
        </q-item>
        <q-item active-class="tab-active" clickable tag="a" to="/short-url-domains">
          <q-item-section avatar>
            <q-icon name="website" />
          </q-item-section>
          <q-item-section>
            <q-item-label>Domains</q-item-label>
          </q-item-section>
        </q-item>
        <!-- <q-item active-class="tab-active" clickable tag="a" to="/short-url-reports">
          <q-item-section avatar>
            <q-icon name="report" />
          </q-item-section>
          <q-item-section>
            <q-item-label>Reports</q-item-label>
          </q-item-section>
        </q-item> -->
      </q-expansion-item>





      <q-item active-class="tab-active" clickable tag="a" to="/contact-groups">
          <q-item-section avatar>
            <q-icon name="groups" />
          </q-item-section>
          <q-item-section>
            <q-item-label>Contact Groups</q-item-label>
          </q-item-section>
      </q-item>

      <q-item active-class="tab-active" clickable tag="a" to="/templates-sms">
        <q-item-section avatar>
          <q-icon name="sms" />
        </q-item-section>
        <q-item-section>
          <q-item-label>SMS Templates</q-item-label>
        </q-item-section>
      </q-item>

      <q-item active-class="tab-active" clickable tag="a" to="/blacklist">
          <q-item-section avatar>
            <q-icon name="cancel" />
          </q-item-section>
          <q-item-section>
            <q-item-label>Blacklist</q-item-label>
          </q-item-section>
      </q-item>

      <q-expansion-item v-model="$store.state.isExpansionSmsOpen" :content-inset-level="0.5" icon="code" label="SMS">
          <q-item active-class="tab-active" clickable tag="a" to="/sms-campaign-builder">
          <q-item-section avatar>
            <q-icon name="campaign" />
          </q-item-section>
          <q-item-section>
            <q-item-label>Campaign Builder</q-item-label>
          </q-item-section>
        </q-item>
        <q-item active-class="tab-active" clickable tag="a" to="/sms-quick-send">
          <q-item-section avatar>
            <q-icon name="send" />
          </q-item-section>
          <q-item-section>
            <q-item-label>Quick Send</q-item-label>
          </q-item-section>
        </q-item>
      </q-expansion-item>

    </q-list>
    </q-drawer>

    <q-page-container>
      <router-view />
    </q-page-container>
  </q-layout>
</template>

<script>
import { getRestEndpoint } from '@/utils/utils'
import { handleClientTokenExpiration, handleTokenExpiration } from '@/utils/auth';
import { ref } from 'vue'
import axios from "axios";
import { isLoggedIn, logoutUser, getUserDetails, getAuthToken } from './utils/auth';

export default {
  name: 'LayoutDefault',
  data () {
    return {
      REST_ENDPOINT: '',
      username: '',
      token: '',
      balance: '',
      balanceLabel: '',
      paymentType: '',
      balanceStartDate: '',
      balanceEndDate: '',
      isLoggedIn: false,
      selProductId: null,
      selProductDescr: '',
      productIds: [],
      dropdownOpen: false
    };
  },
  created() {
    handleClientTokenExpiration(this.$router);
  },
  methods: {
    userLogout() {
      this.balance = '';
      this.balanceLabel = '';
      localStorage.removeItem('userBalance');
      localStorage.removeItem('userBalanceLabel');
      localStorage.removeItem('productIds');
      localStorage.removeItem('selProductId');
      localStorage.removeItem('selProductDescr');
      localStorage.removeItem('userTimezoneOffset');
      logoutUser();
      this.$router.push('/login')
    },
    getBalanceFromAPI() {
      if (this.isLoggedIn) {
        axios.post(`${this.REST_ENDPOINT}reports/balance`, {
          startDate: this.balanceStartDate,
          endDate: this.balanceEndDate
        }, { withCredentials: true })
          .then((response) => {
            const formatter = new Intl.NumberFormat('en-US');
            this.balance = formatter.format(response.data.balance);
            this.balanceLabel = response.data.balanceLabel;
            localStorage.setItem('userBalance', this.balance);
            localStorage.setItem('userBalanceLabel', this.balanceLabel);
          })
          .catch(error => {
            if (error.response && error.response.status === 401 && error.response.data === 'Token expired') {
              handleTokenExpiration(this.$router);
            }
          });
      }
    },
    getProductList() {
      if (this.isLoggedIn) {
        const storedProductIds = localStorage.getItem('productIds');
        const storedSelProductId = localStorage.getItem('selProductId');
        const storedSelProductDescr = localStorage.getItem('selProductDescr');

        if (storedProductIds && storedSelProductId) {
          this.productIds = JSON.parse(storedProductIds);
          this.selProductId = storedSelProductId;
          this.selProductDescr = storedSelProductDescr;
        } else {
          axios.get(`${this.REST_ENDPOINT}reports/product`, { withCredentials: true })
            .then(response => {
              this.productIds = response.data.productList.map(product => product.id);
              this.selProductId = response.data.selProductId;
              localStorage.setItem('productIds', JSON.stringify(this.productIds));
              localStorage.setItem('selProductId', this.selProductId);
            })
            .catch(error => {
              if (error.response && error.response.status === 401 && error.response.data === 'Token expired') {
                handleTokenExpiration(this.$router);
              }
            });
        }
      }
    },
    getPaymentTypeFromAPI() {
      if (this.isLoggedIn) {
        axios.post(`${this.REST_ENDPOINT}reports/payment-type`, {}, { withCredentials: true })
          .then((response) => {
            this.paymentType = response.data.paymentType;
            localStorage.setItem('paymentType', this.paymentType);
          })
          .catch(error => {
            if (error.response && error.response.status === 401 && error.response.data === 'Token expired') {
              handleTokenExpiration(this.$router);
            }
          });
      }
    },
    
    toggleDropdown() {
  this.dropdownOpen = !this.dropdownOpen;
},
selectProduct(productId) {
  this.dropdownOpen = false;
  this.changeProductId(productId);
},
changeProductId(newProductId) {
  const oldProductId = this.selProductId;

  if (newProductId !== oldProductId) {
    axios
      .post(`${this.REST_ENDPOINT}reports/switch-product-id`, { newProductId }, { withCredentials: true })
      .then((response) => {
        const responseNewProductId = response.data.newProductId;
        this.selProductId = responseNewProductId;
        this.selProductDescr = this.productIds.find(product => product.id === responseNewProductId).descr;
        localStorage.setItem('selProductId', responseNewProductId);
        localStorage.setItem('selProductDescr', this.selProductDescr);

        this.getBalanceFromAPI();
        this.getPaymentTypeFromAPI();

        this.$router.push({ path: '/', query: { productChanged: true, productId: responseNewProductId } });
      })
      .catch((error) => {
        console.error('Error switching product ID:', error);
      });
  }
},

  },

  mounted() {
    this.REST_ENDPOINT = getRestEndpoint();
    this.isLoggedIn = isLoggedIn();
    const today = new Date();
    const year = today.getFullYear();
    const month = String(today.getMonth() + 1).padStart(2, '0');
    const day = String(today.getDate()).padStart(2, '0');
    this.balanceStartDate = `${year}.${month}.01`;
    this.balanceEndDate = `${year}.${month}.${day}`;
    this.token = getAuthToken();
    this.username = getUserDetails().username;
    const storedBalance = localStorage.getItem('userBalance');
    if (storedBalance) {
      this.balance = storedBalance;
    }
    const storedBalanceLabel = localStorage.getItem('userBalanceLabel');
    if (storedBalanceLabel) {
      this.balanceLabel = storedBalanceLabel;
    }
    const storedpaymentType = localStorage.getItem('paymentType');
    if (storedpaymentType) {
      this.paymentType = storedpaymentType;
    }
    this.getProductList();
    
  },
  watch: {
    $route(to, from) {
      if (from.name === 'login-user') {
        this.username = getUserDetails().username;
        this.isLoggedIn = isLoggedIn();
        this.getBalanceFromAPI();
        this.getPaymentTypeFromAPI();
        this.getProductList();
      }
    }
    
  },
  setup () {
    return {
      leftDrawerOpen: ref(false),
      confirm: ref(false),
      expanded: ref(false)
    }
  }
}
</script>

<style lang="scss" scoped>
  @import './styles/styles.scss';


  .custom-dropdown {
    position: relative;
    display: inline-block;
    cursor: pointer;
    background-color: #008dd2;
    color: white;
    padding: 0;
    font-family: Montserrat, Helvetica, Arial, serif;

    .dropdown-label {
      display: flex;
      align-items: center;
      width: fit-content;
      padding: 9px ;

      .arrow {
        margin-left: 10px;
        font-size: 15px;
      }
    }

    .dropdown-menu {
      display: none;
      position: absolute;
      background-color: #008dd2;
      color: white;
      list-style: none;
      padding: 0;
      margin: 0;
      width: fit-content;
      z-index: 1;
      box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);

      li {
        display: flex;
        width: max-content;
        flex-direction: column;
        padding: 15px 30px;
        cursor: pointer;
        transition: background-color 0.3s;

        &:hover {
          font-weight: bold;
          width: max-content;
          padding: 15px 30px;
          text-decoration: dotted underline;
        }
        &.selected {
          text-decoration: underline;
          color: lime;
          font-weight: bold;
        }
      }
    }

    &:hover .dropdown-menu,
    &.open .dropdown-menu {
      display: block;
    }
  }

  .user {
    .profile-dropdown {
      position: relative;
      display: inline-block;
      cursor: pointer;
      font-family: Montserrat, Helvetica, Arial, serif;

      .arrow {
        margin-left: 5px;
      }

      .dropdown-menu {
        display: none;
        position: absolute;
        left: -30px;
        padding: 15px 0 0;
        background-color: #008dd2;
        color: white;
        list-style: none;
        width: fit-content;
        z-index: 1;
        box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);

        li {
          display: flex;
          width: max-content;
          flex-direction: column;
          padding: 15px 30px 10px;
          cursor: pointer;
          transition: background-color 0.3s;

          &:hover {
            font-weight: bold;
            width: max-content;
            padding: 15px 30px 10px;
            text-decoration: dotted underline;
          }

        }
      }

      &:hover {
        .dropdown-menu,
        &.open .dropdown-menu {
          display: block;
        }
      }
    }
  }

  .img-logo-header {
    display: flex;

    .img-logo-dash {
      height: 50px;
      margin: 0 auto 5px;
      padding-right: 15px;
    }

  }  
  .appList {
    padding: 10px;
  }
  .q-layout__section--marginal {
    background-color: $altmedia-base-color !important;
  }
  .tab-active {
    background-color: $altmedia-base-color;
    border-radius: 5px;

    .q-item__label {
      color: #fff;
    }

    .q-item__section--avatar {
      color: #fff;
    }
  }

  .appHeader {
    .userInfo {
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
      align-items: center;
      width: 100%;
      font-size: 15px;
      font-family: Montserrat,Helvetica,Arial,serif;

      .buttons {
        display: flex;
        list-style-type: none;
        margin-right: 50px;

        > li {
          margin-right: 30px;
          display: flex;
          justify-content: center;
          align-items: center;
          height: 30px;
          border-right: 1px solid #fff;
          padding-right: 30px;

          a {
            text-decoration: none;
            color: #fff;
            cursor: pointer;
          }
        }

        .welcome {
          .user {
            font-weight: bold;
            text-decoration: underline;
          }
        }
        > li:last-child {
          border: none;
          margin-right: 10px;
          padding-right: 10px;
          margin-left: 15px;
        }
      }
    }
  }

  @media only screen and (max-width: 768px) {
    .appHeader {
      .userInfo {
        font-size: 13px;
        .buttons {
          display: flex;
          flex-direction: column;
          width: 100%;
          > li {

            width: 100%;
            border-right: none;
            justify-content: start;
          }
        }
      }
    }
  }

</style>