<template>
    <div class="q-pa-md q-gutter-sm">
      <q-breadcrumbs>
        <q-breadcrumbs-el icon="home" to="/" />
        <q-breadcrumbs-el label="SMS Templates" icon="sms" to="/templates-sms" />
        <q-breadcrumbs-el label="Duplicate Template" icon="duplicate" />
        <template v-slot:separator>
          <q-icon
            size="1.5em"
            name="chevron_right"
            color="primary"
          />
        </template>
      </q-breadcrumbs>
    </div>
    
    <q-card class="quick-send-card">
      <q-form @submit.prevent="submitForm" class="qForm">
        <q-card-section>
          <div class="text-h6">Duplicate Template</div>
        </q-card-section>
  
        <q-separator inset />
  
        <q-card-section class="column q-gutter-md">
  
          <q-input v-model.trim="form.name" label="Name *" />
          <span class="error-message">{{ formErrors.name }}</span>
  
          <q-input @keyup='charCount()' type="textarea" v-model.trim="form.content" label="Content *" />
          <span class="">{{ totalCharWarning }}</span>
          <span class="error-message">{{ formErrors.content }}</span>
  
        </q-card-section>

        <q-card-actions>
          <q-btn icon="difference" color="altmedia-blue" label="Save Template as duplicate" @click="submitForm()"></q-btn>
        </q-card-actions>
      </q-form>
    </q-card>
  </template>
  
  
  
  <script>
  import { getRestEndpoint, calculateSmsLengthAndCount } from '@/utils/utils';
  import { handleClientTokenExpiration, handleTokenExpiration } from '@/utils/auth';
  import axios from "axios";
    
  export default {
    name: 'SmsTemplatesDuplicate',
  
    data() {
      return {
        REST_ENDPOINT: '',
        nameTotalChar: 200, 
        contentTotalChar: 1000,
        totalCharWarning: null,
        form: {
            name: '',
            content: '',
          },
          formErrors: {
            name: '',
            content: '',
          },
      }
    },
    created() {
      handleClientTokenExpiration(this.$router);
    },
    methods: {
      readDataFromAPI() {
        axios
          .get(`${this.REST_ENDPOINT}sms-template/${this.$route.params.id}`, { withCredentials: true })
          .then((response) => {
            this.form.name = response.data.name;
            this.form.content = response.data.content;
            this.totalChar = 160 - this.form.content.length;
          })
          .catch((error) => {
            if (error.response && error.response.status === 401 && error.response.data === 'Token expired') {
              handleTokenExpiration(this.$router);
            }
          });
      },
      submitForm() {
        this.validateForm();
        if (this.isFormValid()) {
          axios
            .post(`${this.REST_ENDPOINT}sms-template`, {
              name: this.form.name,
              content: this.form.content
            }, { withCredentials: true })
            .then(() => {
              this.loading = false;
              this.$router.push('/templates-sms');
            })
            .catch((error) => {
              if (error.response && error.response.status === 401 && error.response.data === 'Token expired') {
                handleTokenExpiration(this.$router);
              }
            });
        }
      },
      validateForm() {
        if (!this.form.name) {
          this.formErrors.name = 'Name field is required';
        } else if (this.form.name.length > this.nameTotalChar) {
          this.formErrors.name = `Name must not exceed ${this.nameTotalChar} characters`;
        } else {
          this.formErrors.name = '';
        }

        if (!this.form.content) {
          this.formErrors.content = 'Content field is required';
        } else {
          this.formErrors.content = '';
        }
      },
      charCount: function() {
        this.totalCharWarning = calculateSmsLengthAndCount(this.form.content);
        if (this.form.content.length > this.contentTotalChar) {
          this.form.content = this.form.content.substring(0, this.contentTotalChar);
        }
      },
      isFormValid() {
        return Object.values(this.formErrors).every((error) => !error)
      },
    },
    mounted() {
      this.REST_ENDPOINT = getRestEndpoint();
      this.readDataFromAPI();
    },
  }
  </script>
  
  <style lang="scss">
  
  </style>