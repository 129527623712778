<template>
  <div class="q-pa-md q-gutter-sm">
    <q-breadcrumbs>
      <q-breadcrumbs-el icon="home" to="/" />
      <q-breadcrumbs-el label="User Profile Details" icon="account_circle" />
      <template v-slot:separator>
        <q-icon size="1.5em" name="chevron_right" color="primary" />
      </template>
    </q-breadcrumbs>
  </div>

  <q-card class="quick-send-card">
    <q-card-section>
      <div class="text-h6">User Profile Details</div>
    </q-card-section>

    <q-separator inset />

    <q-card-section class="q-gutter-md">

      <div class="row">
        <div class="field-label">Username</div>
        <div class="field-value">{{ user.email }}</div>
      </div>

      <div class="row">
        <div class="field-label">First Name</div>
        <div class="field-value">{{ user.first_name }}</div>
      </div>

      <div class="row">
        <div class="field-label">Last Name</div>
        <div class="field-value">{{ user.last_name }}</div>
      </div>

      <div class="row">
        <div class="field-label">Payment type</div>
        <div class="field-value">{{ user.payment_type }}</div>
      </div>

      <div class="row">
        <div class="field-label">Email</div>
        <div class="field-value">{{ user.email }}</div>
      </div>

      <div class="row">
        <div class="field-label">Timezone</div>
        <div class="field-value">{{ user.timezone }}</div>
      </div>

      <div class="row">
        <div class="field-label">Phone</div>
        <div class="field-value">{{ user.phone }}</div>
      </div>

      <div class="row">
        <div class="field-label">Postcode</div>
        <div class="field-value">{{ user.postcode }}</div>
      </div>

      <div class="row">
        <div class="field-label">Address</div>
        <div class="field-value">{{ user.address }}</div>
      </div>

      <div class="row">
        <div class="field-label">Company</div>
        <div class="field-value">{{ user.company }}</div>
      </div>

      <div class="row">
        <div class="field-label">City</div>
        <div class="field-value">{{ user.city }}</div>
      </div>

      <div class="row">
        <div class="field-label">Country</div>
        <div class="field-value">{{ user.country }}</div>
      </div>
    </q-card-section>

    <q-card-actions>
      <router-link :to="{ name: 'user-profile-edit' }">
        <q-btn icon="edit" color="altmedia-blue" label="Edit Profile" />
      </router-link>
    </q-card-actions>

  </q-card>
</template>

<script>
  import { getRestEndpoint } from '@/utils/utils';
  import { handleClientTokenExpiration, handleTokenExpiration } from '@/utils/auth';
  import axios from "axios";

  export default {
    name: 'UserProfileView',

    data() {
      return {
        REST_ENDPOINT: '',
        user: {
          first_name: '',
          last_name: '',
          payment_type: '',
          email: '',
          timezone: '',
          phone: '',
          postcode: '',
          address: '',
          company: '',
          city: '',
          country: ''
        }
      };
    },
    created() {
      handleClientTokenExpiration(this.$router);
    },
    methods: {
      readDataFromAPI() {
        axios
          .get(`${this.REST_ENDPOINT}register/profile`, { withCredentials: true })
          .then((response) => {
            const data = response.data;
            this.user.first_name = data.first_name;
            this.user.last_name = data.last_name;
            this.user.payment_type = data.payment_type;
            this.user.email = data.email;
            this.user.timezone = data.timezone;
            this.user.phone = data.phone;
            this.user.postcode = data.postcode;
            this.user.address = data.address;
            this.user.company = data.company;
            this.user.city = data.city;
            this.user.country = data.country;
          })
          .catch((error) => {
            if (error.response && error.response.status === 401 && error.response.data === 'Token expired') {
              handleTokenExpiration(this.$router);
            }
          });
      }
    },
    mounted() {
      this.REST_ENDPOINT = getRestEndpoint();
      this.readDataFromAPI();
    },
  };
</script>

<style lang="scss" scoped>
  .q-gutter-md {
        
    .row {
      display: flex;
      margin-top: 30px;
      justify-content: start;
      width: 35%;

      > div {
        display: flex;
        flex-direction: row;
      }

      .field-label {
        width: 25%;
        font-weight: bold;
        text-align: left;
      }

      .field-value {
        width: 50%;
        text-align: left;
      }
    }

  }
</style>
