<template>
  <div class="q-pa-md q-gutter-sm">
    <q-breadcrumbs>
      <q-breadcrumbs-el icon="home" to="/" />
      <q-breadcrumbs-el label="Reports" icon="code"  />
      <q-breadcrumbs-el label="SMS Usage" icon="sms" />
      <template v-slot:separator>
        <q-icon
          size="1.5em"
          name="chevron_right"
          color="primary"
        />
      </template>
    </q-breadcrumbs>
  </div>

  <div class="q-pa-md">
    <q-table
      title="Reports SMS Usage Status"
      :rows="smsUsageItemsGrouped"
      :columns="columns"
      row-key="dcheck"
      :loading="loading"
      :pagination="{ rowsPerPage: 20 }"
      color="primary"
      :filter="filter"
    >
      <template v-slot:body="props">
        <q-tr :props="props">
          <q-td colspan="100%" key="dcheck">{{ props.row.dcheck }} </q-td>
        </q-tr>
        <q-tr v-for="item in props.row.items" :key="item.country">
          <q-td></q-td>
          <q-td class="text-right">{{ item.country }}</q-td>
          <q-td :class="{ total: item.net_name === 'Total' }" class="text-right">{{ item.net_name }}</q-td>
          <q-td :class="{ total: item.net_name === 'Total' }" class="text-right">{{ item.count_total }}</q-td>
          <q-td :class="{ total: item.net_name === 'Total' }" class="text-right">{{ item.count_ok }}</q-td>
          <q-td :class="{ total: item.net_name === 'Total' }" class="text-right">{{ item.count_delivrd }}</q-td>
          <q-td :class="{ total: item.net_name === 'Total' }" class="text-right">{{ item.cost }}</q-td>
          <q-td :class="{ total: item.net_name === 'Total' }" class="text-right">{{ item.deliveredperc }}%</q-td>
        </q-tr>
      </template>

      <template v-slot:top-left>
        <span class="dateRangeValue">
          Period: {{ startDate }} - {{ endDate }}
        </span>

        <span class="dateFilter">
          <q-btn icon="event" color="green" label="Select Period" class="q-ml-lg">
            <q-popup-proxy cover transition-show="scale" transition-hide="scale">
              
                <q-date v-model="dateRange" mask="YYYY-MM-DD" :range="true" range-options="optional">
                <div class="row items-center justify-end q-gutter-sm">
                  <q-btn label="Cancel" color="primary" flat v-close-popup />
                  <q-btn label="OK" color="primary" flat @click="getDateRange" v-close-popup />
                </div>
              </q-date>
            </q-popup-proxy>
          </q-btn>
        </span>

        <q-btn
          class="q-ml-lg"
          color="altmedia-blue"
          icon="archive"
          label="Export"
          no-caps
          @click="exportTableCsv"
        />
      </template>

      <template v-slot:top-right>
        <q-space />
        <q-input dense debounce="300" color="primary" v-model="filter">
          <template v-slot:append>
            <q-icon name="search" />
          </template>
        </q-input>
      </template>
    </q-table>
  </div>
</template>

<script>
import _ from 'lodash';
import moment from 'moment';
import { getRestEndpoint, exportTable } from '@/utils/utils';
import { handleClientTokenExpiration, handleTokenExpiration } from '@/utils/auth';
import axios from 'axios';

export default {
  name: 'ReportsSmsUsageStats',
  data() {
    return {
      loading: false,
      filter: '',
      selected: [],
      smsUsageItems: [],
      smsUsageItemsGrouped: [],
      startDate: '',
      endDate: '',
      dateRange: null,
      REST_ENDPOINT: '',
      columns: [
        {
          name: 'dcheck',
          label: 'Date',
          align: 'left',
          
        },
        { name: 'country', label: 'Country', align: 'right', field: 'country' },
        { name: 'net_name', label: 'Network', align: 'right', field: 'net_name' },
        { name: 'count_total', label: 'Attempts', align: 'right', field: 'count_total' },
        { name: 'count_ok', label: 'Submitted', align: 'right', field: 'count_ok' },
        { name: 'count_delivrd', label: 'Delivered', align: 'right', field: 'count_delivrd' },
        { name: 'cost', label: 'Cost', align: 'right', field: 'cost' },
        { name: 'deliveredperc', label: 'Delivered (%)', align: 'right', field: 'deliveredperc' },
      ],
    };
  },
  created() {
    handleClientTokenExpiration(this.$router);
  },
  
  methods: {

    dateRangeValidator() {
      return this.dateRange != null;
    },
    
    getDateRange() {
      const isSingleDate = typeof this.dateRange === 'string';
      const isRangeSelected = this.dateRange && this.dateRange.from && this.dateRange.to;

      if (isSingleDate) {
        const singleDate = moment(this.dateRange).format('YYYY-MM-DD');
        this.startDate = singleDate;
        this.endDate = singleDate;
      } else if (isRangeSelected) {
        this.startDate = moment(this.dateRange.from).format('YYYY-MM-DD');
        this.endDate = moment(this.dateRange.to).format('YYYY-MM-DD');
      }

      this.smsUsageItems = [];
      this.readDataFromAPI();
    },

    formatDate(val) {
      const formats = ['YYYY.MM.DD HH:mm:ss', 'YYYY-MM-DD HH:mm:ss', 'YYYY.MM.DD', 'YYYY-MM-DD'];
      const momentDate = moment(val, formats, true);

      if (momentDate.isValid()) {
          return momentDate.format('YYYY.MM.DD');
      } else {
          return '';
      }
    },
    
    readDataFromAPI() {
      this.smsUsageItems = [];
      this.smsUsageItemsGrouped = [];
      this.loading = true;
      axios
        .post(`${this.REST_ENDPOINT}reports/sms-usage-stats`, {
          startDate: this.startDate,
          endDate: this.endDate,
        }, { withCredentials: true })
        .then((response) => {
          this.loading = false;
          this.smsUsageItems = response.data;

          if (response.data.length > 1) {
            this.smsUsageItemsGrouped = _.chain(this.smsUsageItems)

              .groupBy((item) => this.formatDate(item.dcheck))

              .map((group, dcheck) => {
                return {
                  dcheck: dcheck,
                  items: group.map((item) => {
                    return {
                      country: item.country !== 'Total' ? item.country : '',
                      net_name: item.net_name,
                      count_total: item.count_total,
                      count_ok: item.count_ok,
                      count_delivrd: item.count_delivrd,
                      cost: item.cost,
                      deliveredperc: item.count_ok === 0 ? 0 : ((item.count_delivrd / item.count_ok) * 100).toFixed(),
                    };
                  }),
                };
              })
              .value();
          }
        })
        .catch((error) => {
          this.loading = false;
          if (error.response && error.response.status === 401 && error.response.data === 'Token expired') {
            handleTokenExpiration(this.$router);
          }
        });
    },

    exportTableCsv() {
      const expFileName = this.startDate === this.endDate ? this.startDate : `${this.startDate}_${this.endDate}`;
      exportTable(this.columns, this.smsUsageItems, 'sms_stats_', expFileName);
    },
  },
  mounted() {
    const today = moment().format('YYYY-MM-DD');
    this.startDate = today;
    this.endDate = today;
    this.REST_ENDPOINT = getRestEndpoint();
    this.readDataFromAPI();
  },
};
</script>

<style lang="scss" scoped>
  .total {
    font-weight: bold;
    text-align: right;
  }
  .dateFilter {
    .dateRangeValue {
      margin-left: 15px;
      margin-right: 15px;
    }
  }
</style>