<template>
  <div class="q-pa-md q-gutter-sm">
    <q-breadcrumbs>
      <q-breadcrumbs-el icon="home" to="/" />
      <q-breadcrumbs-el label="API Key" icon="key" />
      <template v-slot:separator>
        <q-icon size="1.5em" name="chevron_right" color="primary" />
      </template>
    </q-breadcrumbs>
  </div>



  <q-card class="">
    <q-form   class="qForm">
      <q-card-section>
        <div class="text-h6">API Key</div>
      </q-card-section>

      <q-separator inset />

      <q-card-section class="column">
        
        <div v-if="apiKey">
          <div class="text-title">Your API Key:</div>
          <div class="api-key-value">{{ apiKey }}</div>
          <div class="text-title">API Code:</div>
          <div class="api-code-value">{{ apiCode }}</div>

          <!-- <div class="inputs2">
            <q-input filled v-model="recipient" label="Recipient" />
            <q-input filled v-model="message" label="Message" />
            <q-input filled v-model="sender" label="Sender" />
          </div> -->

          <q-card-actions class="buttons">
        
            <q-btn class="button" color="primary" icon="content_copy" label="Copy key" @click="copyKey" />
            <q-btn class="button" color="negative" icon="delete" label="Delete key" @click="confirmDeleteKey" />
            <q-btn class="button" color="positive" icon="refresh" label="Refresh key" @click="confirmRefreshKey" />
            <!-- <q-btn class="button" color="orange" icon="send" label="Test GET" @click="testKeyGet" />
            <q-btn class="button" color="brown" icon="send" label="Test POST" @click="testKeyPost" /> -->

          </q-card-actions>
          
        </div>
        
        <div class="no-key" v-else>
          <div class="no-key-message">No API Key found</div>
          <div class="new-key-button">
            <q-btn color="positive" icon="add" label="New key" @click="generateNewKey" />
          </div>
        </div>

      </q-card-section>

      
    </q-form>
  </q-card>


  <!-- Confirmation Dialog for Delete -->
  <q-dialog v-model="confirmDelete">
    <q-card>
      <q-card-section>
        Are you sure you want to delete this API key?
      </q-card-section>
      <q-card-actions align="right">
        <q-btn flat label="Cancel" color="primary" v-close-popup />
        <q-btn flat label="Delete" color="negative" @click="deleteKey" v-close-popup />
      </q-card-actions>
    </q-card>
  </q-dialog>

  <!-- Confirmation Dialog for Refresh -->
  <q-dialog v-model="confirmRefresh">
    <q-card>
      <q-card-section>
        Are you sure you want to refresh this API key?
      </q-card-section>
      <q-card-actions align="right">
        <q-btn flat label="Cancel" color="primary" v-close-popup />
        <q-btn flat label="Refresh" color="positive" @click="refreshKey" v-close-popup />
      </q-card-actions>
    </q-card>
  </q-dialog>
</template>

<script>
import { getRestEndpoint } from '@/utils/utils';
import axios from 'axios';
import { copyToClipboard } from 'quasar';

export default {
  name: 'ApiKey',

  data() {
    return {
      apiKey: null,
      apiCode: null,
      recipient: '',
      message: '',
      sender: '',
      REST_ENDPOINT: '',
      confirmDelete: false,
      confirmRefresh: false,
    };
  },
  mounted() {
    this.REST_ENDPOINT = getRestEndpoint();
    this.fetchApiKey();
  },
  methods: {
    async fetchApiKey() {
      try {
        const response = await axios.get(`${this.REST_ENDPOINT}key/key`, { withCredentials: true });
        if (response.data.apiKey) {
          this.apiKey = response.data.apiKey['apiKey'];
        } else {
          this.apiKey = null;
        }
        this.apiCode = response.data.apiCode;
      } catch (error) {
        console.error('Error fetching key:', error);
      }
    },
    async generateNewKey() {
      try {
        const response = await axios.get(`${this.REST_ENDPOINT}key/new-key`, { withCredentials: true });
        this.apiKey = response.data.apiKey;
        this.apiCode = response.data.apiCode;
      } catch (error) {
        console.error('Error generating new key:', error);
      }
    },
    confirmDeleteKey() {
      this.confirmDelete = true;
    },
    confirmRefreshKey() {
      this.confirmRefresh = true;
    },
    async deleteKey() {
      try {
        await axios.delete(`${this.REST_ENDPOINT}key/delete-key`, { withCredentials: true });
        this.apiKey = null;
        this.apiCode = null;
      } catch (error) {
        console.error('Error deleting key:', error);
      }
    },
    async refreshKey() {
      try {
        const response = await axios.get(`${this.REST_ENDPOINT}key/refresh-key`, { withCredentials: true });
        this.apiKey = response.data.apiKey;
        this.apiCode = response.data.apiCode;
      } catch (error) {
        console.error('Error refreshing key:', error);
      }
    },
    copyKey() {
      copyToClipboard(this.apiKey).catch((error) => {
        console.error('Failed to copy API Key:', error);
      });
    }
    // async testKeyGet() {
    //   try {
    //     const response = await axios.get(`${this.REST_ENDPOINT}restapi/send`, {
    //       params: {
    //         apiCode: this.apiCode,
    //         apiKey: this.apiKey,
    //         recipient: this.recipient,
    //         message: this.message,
    //         sender: this.sender
    //       }
    //     });
    //     console.log('Response:', response);
    //   } catch (error) {
    //     console.error('Error:', error);
    //   }
    // },
    // async testKeyPost() {
    //   try {
    //     const response = await axios.post(`${this.REST_ENDPOINT}restapi/send`, {
    //       apiCode: this.apiCode,
    //       apiKey: this.apiKey,
    //       recipient: this.recipient,
    //       message: this.message,
    //       sender: this.sender
    //     });
    //     console.log('Response:', response);
    //   } catch (error) {
    //     console.error('Error:', error);
    //   }
    // }
  },
};
</script>

<style lang="scss" scoped>

.no-key {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;

  .no-key-message {
    margin: 10px 0 0;
  }

  .new-key-button {
    margin: 20px 0;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    width: 100%;
  }
}

.text-title {
    font-weight: bold;
    font-size: 18px;
    margin: 20px 0 5px;
  }

.api-key-value {
    margin: 20px 4px;
    padding: 4px;
    width: 75%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .api-code-value {
    margin: 20px 4px;
    padding: 4px;
    width: 75%;
    overflow: auto;
    white-space: nowrap;
    display: flex;
  }

.buttons {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 20px 0;
  }

  .button {
    margin: 0 10px;
  }
</style>
