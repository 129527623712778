<template>
  <div class="q-pa-md q-gutter-sm">
    <q-breadcrumbs>
      <q-breadcrumbs-el icon="home" to="/" />
      <q-breadcrumbs-el label="Reports" icon="code"  />
      <q-breadcrumbs-el label="EDR SMS detail records" icon="receipt" />
      <template v-slot:separator>
        <q-icon
          size="1.5em"
          name="chevron_right"
          color="primary"
        />
      </template>
    </q-breadcrumbs>
  </div>

  <div class="q-pa-md table-container">
    <q-table
      title = "Reports EDR"
      :rows = "edrReportsItems"
      :columns = "columns"
      row-key = "id"
      :loading = "loading"
      :pagination="{ rowsPerPage: 20 }"
      color = "primary"
      :filter="filter"
    >
        
      <template v-slot:top-left>

        <div class="tableActions">
        
          <span class="dateFilter">

            <span class="dateFilterStart">
              <q-input filled v-model="startDate" class="startDate">
                <template v-slot:prepend>
                  <q-icon name="event" class="cursor-pointer">
                    <q-popup-proxy cover transition-show="scale" transition-hide="scale">
                      <q-date v-model="startDate" mask="YYYY-MM-DD HH:mm:ss">
                        <div class="row items-center justify-end">
                          <q-btn v-close-popup label="Close" color="primary" flat />
                        </div>
                      </q-date>
                    </q-popup-proxy>
                  </q-icon>
                </template>

                <template v-slot:append>
                  <q-icon name="access_time" class="cursor-pointer">
                    <q-popup-proxy cover transition-show="scale" transition-hide="scale">
                      <q-time v-model="startDate" mask="YYYY-MM-DD HH:mm:ss" format24h>
                        <div class="row items-center justify-end">
                          <q-btn v-close-popup label="Close" color="primary" flat />
                        </div>
                      </q-time>
                    </q-popup-proxy>
                  </q-icon>
                </template>
              </q-input>
            </span>

            <span class="sep"> - </span>

            <span class="dateFilterEnd">
              <q-input filled v-model="endDate" class="q-ml-lg">
                <template v-slot:prepend>
                  <q-icon name="event" class="cursor-pointer">
                    <q-popup-proxy cover transition-show="scale" transition-hide="scale">
                      <q-date v-model="endDate" mask="YYYY-MM-DD HH:mm">
                        <div class="row items-center justify-end">
                          <q-btn v-close-popup label="Close" color="primary" flat />
                        </div>
                      </q-date>
                    </q-popup-proxy>
                  </q-icon>
                </template>

                <template v-slot:append>
                  <q-icon name="access_time" class="cursor-pointer">
                    <q-popup-proxy cover transition-show="scale" transition-hide="scale">
                      <q-time v-model="endDate" mask="YYYY-MM-DD HH:mm" format24h>
                        <div class="row items-center justify-end">
                          <q-btn v-close-popup label="Close" color="primary" flat />
                        </div>
                      </q-time>
                    </q-popup-proxy>
                  </q-icon>
                </template>
              </q-input>
            </span>

            <q-btn
              class="q-ml-lg"
              color="positive"
              icon="filter_list"
              label="Filter"
              no-caps
              @click="fetchItems()"
            />
            
          </span>

          <q-btn
            class="q-ml-lg"
            color="altmedia-blue"
            icon="archive"
            label="Export"
            no-caps
            @click="exportTableCsv"
          />

      </div>
        
      </template>

      <template v-slot:top-right>
        <q-space />
        <q-input  dense debounce="300" color="primary" v-model="filter">
          <template v-slot:append>
            <q-icon name="search" />
          </template>
        </q-input>
      </template>
        
    </q-table>
  </div>
</template>
  
<script>
import { getRestEndpoint, exportTable } from '@/utils/utils';
import axios from 'axios';
import moment from 'moment';

import { handleClientTokenExpiration, handleTokenExpiration } from '@/utils/auth';
import { convertServerTimeToUserTime } from '@/utils/utils';


export default {
  name: 'ReportsEdr',
  data() {
    return {
      REST_ENDPOINT: '',
      loading: false,
      filter: '',
      startDate: '',
      endDate: '',
      dateRange: null,
      selected: [],
      edrReportsItems: [],
      utcOffset: 0,
      columns: [
        {
          name: 'edr_date',
          required: true,
          label: 'Date',
          align: 'right',
          field: row => row.edr_date,
          format: (val) => {
            const formattedDate = moment.utc(val, 'YYYY.MM.DD HH:mm:ss').add(this.utcOffset, 'hours');
            return formattedDate.format('YYYY-MM-DD HH:mm:ss');
          },
          sortable: true
        },
        { name: 'sender_name', align: 'right', label: 'Sender', field: 'sender_name', sortable: true },
        { name: 'dnis', align: 'right', label: 'Destination', field: 'dnis', sortable: true },
        { name: 'network', align: 'right', label: 'Network', field: 'network', sortable: true },
        { name: 'mccmnc', align: 'right', label: 'MCCMNC', field: 'mccmnc', sortable: true },
        { name: 'country', align: 'right', label: 'Country', field: 'country', sortable: true },
        { name: 'text', align: 'right', label: 'Text', field: 'text', sortable: true, style: 'max-width: 300px; white-space: nowrap; overflow: hidden; text-overflow: ellipsis;' },
        { name: 'status', align: 'right', label: 'Status', field: 'status', sortable: true },
        { name: 'client_rate', align: 'right', label: 'Rate', field: 'client_rate', sortable: true },
      ]

    };
  },
  created() {
    handleClientTokenExpiration(this.$router);
  },
  mounted() {
    this.REST_ENDPOINT = getRestEndpoint();
    const serverTime = new Date();
    this.utcOffset = parseInt(sessionStorage.getItem('userTimezoneOffset'), 10);
    const userTimeFormatted = convertServerTimeToUserTime(serverTime, this.utcOffset);
    const userTimeMoment = moment(userTimeFormatted, 'YYYY-MM-DD HH:mm');
    this.startDate = userTimeMoment.clone().subtract(1, 'hour').format('YYYY-MM-DD HH:mm:ss');
    this.endDate = userTimeMoment.format('YYYY-MM-DD HH:mm:ss');
  },
  methods: {
    async fetchItems() {
      this.edrReportsItems = [];
      this.loading = true;

      const startDateFormatted = moment(this.startDate, 'YYYY-MM-DD HH:mm:ss')
        .subtract(this.utcOffset, 'hours')
        .format('YYYY-MM-DD HH:mm:ss');
      const endDateFormatted = moment(this.endDate, 'YYYY-MM-DD HH:mm:ss')
        .subtract(this.utcOffset, 'hours')
        .format('YYYY-MM-DD HH:mm:ss');

      try {
        const response = await axios.post(`${this.REST_ENDPOINT}reports/sms-edr`, {
          startDate: startDateFormatted,
          endDate: endDateFormatted
        }, { withCredentials: true });

        this.edrReportsItems = response.data;
        this.loading = false;
      } catch (error) {
        this.loading = false;
        if (error.response && error.response.status === 401 && error.response.data === 'Token expired') {
          handleTokenExpiration(this.$router);
        } else {
          console.error('Error fetching data:', error.message || error.response.data);
        }
      }
    },

    exportTableCsv() {
      let expFileName = '';
      if (this.startDate === this.endDate) {
        expFileName = this.startDate;
      } else {
        expFileName = `${this.startDate}_${this.endDate}`;
      }
      exportTable(this.columns, this.edrReportsItems, 'edrs_', expFileName);
    }
  }
};
</script>
  
<style lang="scss">

  .dateRangeValue {
    margin: 10px 0 20px;
    display: flex;

    .label {
      font-weight: bold;
    }
  }

  .tableActions {
    display: flex;

    .dateFilter {
      display: flex;
    }

    .sep {
      margin: auto;
      padding-left: 20px;
      font-size: 30px;
      font-weight: normal;
      color: #555;
    }
  }
  
  .table-container {
    overflow-x: auto;
  }
</style>