<template>
  <div class="q-pa-md q-gutter-sm">
    <q-breadcrumbs>
      <q-breadcrumbs-el icon="home" to="/" />
      <q-breadcrumbs-el label="Edit User Profile" icon="edit" />
      <template v-slot:separator>
        <q-icon size="1.5em" name="chevron_right" color="primary" />
      </template>
    </q-breadcrumbs>

    <q-card class="quick-send-card">
      <q-form @submit.prevent="submitForm" class="qForm">
        <q-card-section>
          <div class="text-h6">Edit Profile</div>
        </q-card-section>

        <q-separator inset />

        <q-card-section class="column q-gutter-md">
          <q-input v-model.trim="form.first_name" label="First Name *" />
          <span class="error-message">{{ formErrors.first_name }}</span>

          <q-input v-model.trim="form.last_name" label="Last Name *" />
          <span class="error-message">{{ formErrors.last_name }}</span>

          <q-select
            :options="optionsTimezone"
            label="Timezone *"
            option-label="label"
            v-model="selTimezone"
          />
          <span class="error-message">{{ formErrors.timezone }}</span>

          <q-input v-model.trim="form.phone" label="Phone" />
          <span class="error-message">{{ formErrors.phone }}</span>

          <q-input v-model.trim="form.postcode" label="Postcode" />
          <span class="error-message">{{ formErrors.postcode }}</span>

          <q-input v-model.trim="form.address" label="Address" />
          <span class="error-message">{{ formErrors.address }}</span>

          <q-input v-model.trim="form.company" label="Company" />
          <span class="error-message">{{ formErrors.company }}</span>

          <q-input v-model.trim="form.city" label="City" />
          <span class="error-message">{{ formErrors.city }}</span>

          <q-select
            :options="optionsCountries"
            label="Country *"
            option-label="name" 
            v-model="selCountry"
          />

          <span class="error-message">{{ formErrors.country }}</span>                  
        </q-card-section>

        <q-card-actions>
          <q-btn 
            icon="edit" 
            color="altmedia-blue" 
            label="Update Profile" 
            @click="submitForm" 
          />
        </q-card-actions>
      </q-form>
    </q-card>
  </div>
</template>

<script>
import { getRestEndpoint } from '@/utils/utils';
import { handleClientTokenExpiration, handleTokenExpiration } from '@/utils/auth';
import axios from 'axios';

export default {
  name: 'UserProfileEdit',

  data() {
    return {
      REST_ENDPOINT: '',
      optionsCountries: [],
      selCountry: '',
      selTimezone: null,
      loading: false,

      form: {
        first_name: '',
        last_name: '',
        timezone: '',
        phone: '',
        postcode: '',
        address: '',
        company: '',
        city: '',
        country_id: '',
        country: ''
      },
      formErrors: {
        first_name: '',
        last_name: '',
        timezone: '',
        phone: '',
        postcode: '',
        address: '',
        company: '',
        city: '',
        country_id: '',
        country: ''
      }
    };
  },

  created() {
    handleClientTokenExpiration(this.$router);
  },

  methods: {
    async loadProfileAndCountries() {
      try {
        const [countriesResponse, profileResponse] = await Promise.all([
          this.getCountries(),
          this.readDataFromAPI()
        ]);

        this.optionsCountries = countriesResponse.data;
        const profileData = profileResponse.data;

        this.form = { ...this.form, ...profileData };

        this.selTimezone = this.optionsTimezone.find(
          (timezone) => timezone.value === profileData.timezone
        );

        this.selCountry = this.optionsCountries.find(
          (country) => country.id === profileData.country_id
        );
      } catch (error) {
        if (error.response && error.response.status === 401 && error.response.data === 'Token expired') {
          handleTokenExpiration(this.$router);
        } else {
          console.error('Error loading profile or countries:', error);
        }
      }
    },

    getCountries() {
      return axios.get(`${this.REST_ENDPOINT}utils/countries`);
    },

    readDataFromAPI() {
      return axios.get(`${this.REST_ENDPOINT}register/profile`, { withCredentials: true });
    },

    submitForm() {
      this.validateForm();
      if (this.isFormValid()) {
        axios
          .put(
            `${this.REST_ENDPOINT}register/profile`,
            {
              first_name: this.form.first_name,
              last_name: this.form.last_name,
              timezone: this.selTimezone.value,
              phone: this.form.phone,
              postcode: this.form.postcode,
              address: this.form.address,
              company: this.form.company,
              city: this.form.city,
              country_id: this.selCountry.id
            },
            { withCredentials: true }
          )
          .then(() => {
            this.$router.push('/user-profile-view');
          })
          .catch((error) => {
            if (error.response && error.response.status === 401 && error.response.data === 'Token expired') {
              handleTokenExpiration(this.$router);
            } else {
              console.error('Error updating profile:', error);
            }
          });
      }
    },

    validateForm() {
      this.formErrors = {};
      this.formErrors.first_name = !this.form.first_name ? 'First name is required' : '';
      this.formErrors.last_name = !this.form.last_name ? 'Last name is required' : '';
      if (!this.selTimezone) {
        this.formErrors.timezone = 'Timezone is required';
      }
      if (!this.selCountry) {
        this.formErrors.country = 'Country is required';
      }
    },

    isFormValid() {
      return Object.values(this.formErrors).every((error) => !error);
    }
  },

  computed: {
    optionsTimezone() {
      return [
        { label: 'UTC-12', value: 'UTC-12' },
        { label: 'UTC-11', value: 'UTC-11' },
        { label: 'UTC-10', value: 'UTC-10' },
        { label: 'UTC-9', value: 'UTC-9' },
        { label: 'UTC-8', value: 'UTC-8' },
        { label: 'UTC-7', value: 'UTC-7' },
        { label: 'UTC-6', value: 'UTC-6' },
        { label: 'UTC-5', value: 'UTC-5' },
        { label: 'UTC-4', value: 'UTC-4' },
        { label: 'UTC-3', value: 'UTC-3' },
        { label: 'UTC-2', value: 'UTC-2' },
        { label: 'UTC-1', value: 'UTC-1' },
        { label: 'UTC', value: 'UTC' },
        { label: 'UTC+1', value: 'UTC+1' },
        { label: 'UTC+2', value: 'UTC+2' },
        { label: 'UTC+3', value: 'UTC+3' },
        { label: 'UTC+4', value: 'UTC+4' },
        { label: 'UTC+5', value: 'UTC+5' },
        { label: 'UTC+6', value: 'UTC+6' },
        { label: 'UTC+7', value: 'UTC+7' },
        { label: 'UTC+8', value: 'UTC+8' },
        { label: 'UTC+9', value: 'UTC+9' },
        { label: 'UTC+10', value: 'UTC+10' },
        { label: 'UTC+11', value: 'UTC+11' },
        { label: 'UTC+12', value: 'UTC+12' }
      ];
    }
  },

  mounted() {
    this.REST_ENDPOINT = getRestEndpoint();
    this.loadProfileAndCountries();
  }
};

</script>

<style lang="scss">
.error-message {
  color: red;
  font-size: 12px;
}
</style>
