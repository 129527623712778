<template>
  <div class="q-pa-md q-gutter-none">
    
    <q-breadcrumbs>
      <q-breadcrumbs-el icon="home" to="/" />
      <q-breadcrumbs-el label="SMS" icon="code" />
      <q-breadcrumbs-el label="Quick Send" icon="send" />
      <template v-slot:separator>
        <q-icon size="1.5em" name="chevron_right" color="primary" />
      </template>
    </q-breadcrumbs>
  </div>

  <q-card class="sms-card">
    <q-form @submit.prevent="submitForm" class="qForm">
      <q-card-section>
        <div class="text-h6">SMS Quick Send</div>
      </q-card-section>

      <q-separator inset />

      <q-card-section class="column q-gutter-md">
        <q-input v-model.trim="form.recipient" label="Recipient *" />
        <span class="error-message">{{ formErrors.recipient }}</span>

        <q-select 
          outlined
          v-model="selectedOptionsSender"
          :options="optionsSenders"
          label="Sender ID"
        />
        <span class="error-message">{{ formErrors.sender }}</span>

        <q-select 
          outlined
          v-model="selectedOptionsTemplate"
          :options="optionsTemplates"
          label="SMS Template"
          emit-value
          map-options
          @update:model-value="populateTextarea"
        />

        <q-checkbox
          v-model="showShortUrls"
          label="Show Short URLs"
          color="primary"
        />

        <div v-if="showShortUrls && shortUrlLinksItems.length > 0" class="short-url-list">
          <div v-for="item in shortUrlLinksItems" :key="item.id" class="short-url-item">
            <div class="short-url">
              <span class="short-url-text">{{ item.fulllink }}</span>
            </div>
            <div class="full-url">
              <span class="full-url-text">{{ item.target_url }}</span>
            </div>

            <q-btn 
              icon="content_copy" 
              color="positive" 
              @click="copyToClipboard(item.fulllink)"
              size="sm"
              class="copy-btn"
            >
              <q-tooltip class="bg-positive">Copy Url</q-tooltip>
            </q-btn>
            
          </div>
        </div>

        <q-input
          @keyup="charCount"
          type="textarea"
          v-model.trim="form.message"
          label="Message *"
        />
        <span>{{ totalCharWarning }}</span>
        <span class="error-message">{{ formErrors.message }}</span>
      </q-card-section>

      <q-card-actions>
        <q-btn icon="send" color="altmedia-blue" label="Send" @click="submitForm()" />
      </q-card-actions>

      <span class="msg-response" :class="{ 'success': sendResponseSuccess, 'error': !sendResponseSuccess }">{{ sendResponse }}</span>
    </q-form>
  </q-card>
</template>

<script>
import { getRestEndpoint, calculateSmsLengthAndCount } from '@/utils/utils';
import { handleClientTokenExpiration, handleTokenExpiration } from '@/utils/auth';
import axios from "axios";

export default {
  name: 'SmsQuickSend',

  data() {
    return {
      form: {
        recipient: '',
        sender: '',
        message: ''
      },
      formErrors: {
        recipient: '',
        sender: '',
        message: ''
      },
      sendResponse: '',
      sendResponseSuccess: null,
      sendUsername: null,
      sendPassword: null,
      sender: null,
      REST_ENDPOINT: '',
      selectedOptionsTemplate: null,
      selectedOptionsSender: null,
      contentTotalChar: 1000,
      totalCharWarning: null,
      optionsTemplates: [],
      optionsSenders: [],
      shortUrlLinksItems: [],
      showShortUrls: false 
    }
  },
  created() {
    handleClientTokenExpiration(this.$router);
  },
  methods: {
    async submitForm() {
      this.sendResponse = '';
      this.validateForm();
      if (this.isFormValid()) {
        try {
          const response = await axios.post(`${this.REST_ENDPOINT}send/quick`, {
            recipient: this.form.recipient,
            text: this.form.message,
            selectedOptionsSender: this.selectedOptionsSender.label
          }, { withCredentials: true });

          if (response.data.error) {
            this.sendResponseSuccess = false;
            this.sendResponse = "Error: " + response.data.message + ": " + response.data.details;
          } else {
            if (Array.isArray(response.data)) {
              const totalSegments = response.data.length;
              this.sendResponseSuccess = true;
              this.sendResponse = "Message sent successfully! Total number of SMS sent: " + totalSegments;
            } else {
              this.sendResponseSuccess = true;
              this.sendResponse = "Message sent successfully! Total number of SMS sent: 1";
            }
          }
        } catch (error) {
          this.sendResponseSuccess = false;
          if (error.response && error.response.data && error.response.data.message) {
            this.sendResponse = "Error: " + error.response.data.message;
          } else {
            this.sendResponse = "Error: Failed to send the message " + error.response.data;
          }
        }
      }
    },

    validateForm() {
      if (!this.form.recipient) {
        this.formErrors.recipient = 'Recipient field is required';
      } else {
        this.formErrors.recipient = '';
      }

      if (!this.selectedOptionsSender) { 
        this.formErrors.sender = 'Sender ID field is required';
      } else {
        this.formErrors.sender = '';
      }

      if (!this.form.message) {
        this.formErrors.message = 'Message field is required'
      } else {
        this.formErrors.message = ''
      }
    },

    isFormValid() {
      return Object.values(this.formErrors).every((error) => !error)
    },

    charCount() {
      this.totalCharWarning = calculateSmsLengthAndCount(this.form.message);
      if (this.form.message.length > this.contentTotalChar) {
        this.form.message = this.form.message.substring(0, this.contentTotalChar);
      }
    },

    getSmsTemplates() {
      axios.get(`${this.REST_ENDPOINT}sms-template`, { withCredentials: true })
        .then((response) => {
          this.optionsTemplates = response.data.map((item) => ({
            label: item.name,
            value: item.content,
          }));
        })
        .catch((error) => {
          if (error.response && error.response.status === 401 && error.response.data === 'Token expired') {
            handleTokenExpiration(this.$router);
          }
        });
    },

    getSenders() {
      axios.get(`${this.REST_ENDPOINT}sender/active-senders`, { withCredentials: true })
        .then((response) => {
          this.optionsSenders = response.data.map((item) => ({
            label: item.sender_name,
            value: item.sender_id,
          }));
        })
        .catch((error) => {
          if (error.response && error.response.status === 401 && error.response.data === 'Token expired') {
            handleTokenExpiration(this.$router);
          }
        });
    },

    getShortUrls() {
      this.loading = true;
      axios.get(`${this.REST_ENDPOINT}short-url-links`, { withCredentials: true })
        .then((response) => {
          this.loading = false;
          this.shortUrlLinksItems = response.data
            .filter(item => item.disabled !== 1)
            .map(item => ({
              ...item,
              fulllink: `${item.domain_name}/${item.link}`,
            }))
            .sort((a, b) => b.id - a.id);
        })
        .catch((error) => {
          this.loading = false;
          if (error.response && error.response.status === 401 && error.response.data === 'Token expired') {
            handleTokenExpiration(this.$router);
          }
        });
    },

    populateTextarea() {
      this.form.message = this.selectedOptionsTemplate || '';
      this.charCount();
    },

    copyToClipboard(text) {
      navigator.clipboard.writeText(text);
    }
  },

  mounted() {
    this.REST_ENDPOINT = getRestEndpoint();
    this.getSmsTemplates();
    this.getSenders();
    this.getShortUrls();
  }
}
</script>

<style lang="scss" scoped>
  .sms-card {
    width: 98% !important;
    margin: 10px 20px;
  }

  .msg-response {
    width: 100%;
    display: block;
    margin-top: 25px;
    margin-left: 10px;
    font-size: 1rem;
  }

  .msg-response.success {
    color: green;
  }
  .msg-response.error {
    color: red;
  }

  .short-url-list {
    width: 100%;
    margin-top: 10px;
  }

  .short-url-item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 10px;
  }

  .short-url {
    flex: 0 0 250px;
    padding: 5px 0;
    margin: 5px 0;
    font-weight: bold;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .full-url {
    flex: 1;
    padding: 5px 0;
    margin: 5px 0 5px 10px;
    color: #999;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .copy-btn {
    margin-left: 10px;
  }
</style>
