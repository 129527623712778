<template>

    <div class="q-pa-md q-gutter-sm">
      <q-breadcrumbs>
        <q-breadcrumbs-el icon="home" to="/" />
        <q-breadcrumbs-el label="Short Url" icon="code"  />
        <q-breadcrumbs-el label="Short Url Reports" icon="report" />
        <template v-slot:separator>
          <q-icon
            size="1.5em"
            name="chevron_right"
            color="primary"
          />
        </template>
      </q-breadcrumbs>
    </div>
  
    <div class="q-pa-md">
      <q-table
        title = "Sender"
        :rows = "filteredSenderItems"
        :columns = "columns"
        row-key = "id"
        :loading = "loading"
        :pagination="{ rowsPerPage: 20 }"
        color = "primary"
        :filter="filter"
      >
  
      <template v-slot:body-cell="{ row, col }">
        <q-td>
          <span :class="{'text-grey': row.status === 'disabled'}">
            {{ getField(row, col) }}
          </span>
        </q-td>
      </template>
  
      <template v-slot:body-cell-actions="scope">
  
        <q-td :props="scope">
          <q-btn
            flat
            round
            icon="sync_disabled"
            :color="scope.row.status === 'disabled' ? 'grey' : 'negative'"
            :disable="scope.row.status === 'disabled'"
            @click="disableItem(scope.row.id)"
          >
          <q-tooltip class="bg-negative">Disable this sender</q-tooltip>
        </q-btn>
  
        </q-td>
        </template>
  
        <template v-slot:loading>
          <q-inner-loading showing color="primary" />
        </template>
  
        <template v-slot:top-left>
  
          <q-btn class="q-ml-lg" color="positive" icon="add" label="Request New" to="/sender-add" />
          <q-btn
            class="q-ml-lg"
            color="altmedia-blue"
            icon="archive"
            label="Export"
            no-caps
            @click="exportTableCsv"
          />
          
          <q-space />
          </template>
  
          <template v-slot:top-right>
          <q-input  dense debounce="300" color="primary" v-model="filter">
            <template v-slot:append>
              <q-icon name="search" />
            </template>
          </q-input>
        </template>
        
        </q-table>
    </div>
  </template>
    
  <script>
  import { getRestEndpoint, exportTable } from '@/utils/utils';
  import moment from 'moment';
  import { handleClientTokenExpiration, handleTokenExpiration } from '@/utils/auth';
  import axios from 'axios';
  import { ref } from 'vue';
  
  export default {
    name: 'ShortUrlReports',
    data() {
      return {
        loading: false,
        filter: '',
        status: 'all',
        REST_ENDPOINT: '',
        senderItems: [],
        filteredSenderItems: [],
        columns: [
          { name: 'name', align: 'left', label: 'Name', field: 'name', sortable: true },
          { name: 'countries', align: 'left', label: 'Countries', field: 'countries' },
          { name: 'created_at', align: 'center', label: 'Created', field: 'created_at', sortable: true, format: (val) => moment(val).fromNow() },
          { name: 'status', align: 'left', label: 'Status', field: 'status', sortable: true },
          { name: 'actions', align: 'center', label: 'Actions', sortable: false, style: 'width: 150px' },
        ],
      };
    },
    created() {
      handleClientTokenExpiration(this.$router);
    },
    computed: {
      filteredItems() {
        if (this.status === 'all') {
          return this.senderItems;
        } else {
          return this.senderItems.filter((item) => item.status === this.status);
        }
      },
    },
    methods: {
      getField(row, col) {
        if (col.field === 'created_at') {
          return moment(row[col.field]).fromNow();
        } else if (col.field === 'countries') {
          return row[col.field].split(',').map((country) => country.trim()).join(', ');
        } else {
          return row[col.field];
        }
      },
      filterClick(status) {
        if (status === 'all') {
          this.filteredSenderItems = this.senderItems;
        } else {
          this.filteredSenderItems = this.senderItems.filter((item) => item.status === status);
        }
      },
      exportTableCsv() {
        const columnsWithoutActions = [...this.columns];
        columnsWithoutActions.pop();
        exportTable(columnsWithoutActions, this.senderItems, 'sender_');
      },
      readDataFromAPI() {
        this.loading = true;
        axios
          .get(`${this.REST_ENDPOINT}sender`, { withCredentials: true })
          .then((response) => {
            this.loading = false;
            this.senderItems = response.data.map((item) => ({
              ...item,
              countries: item.countries.split(',').map((country) => country.trim()).join(', '),
            }));
            this.filteredSenderItems = this.senderItems;
          })
          .catch((error) => {
            if (error.response && error.response.status === 401 && error.response.data === 'Token expired') {
              handleTokenExpiration(this.$router);
            } else {
              this.loading = false;
            }
          });
      },
      async disableItem(id) {
        if (confirm('Are you sure?')) {
          try {
            await axios.put(`${this.REST_ENDPOINT}sender/${id}`, {}, { withCredentials: true });
            this.readDataFromAPI()
              this.disableFilterClick()
          } catch (error) {
            if (error.response && error.response.status === 401 && error.response.data === 'Token expired') {
              handleTokenExpiration(this.$router);
            }
          }
        }
      },

  
    },
    mounted() {
      this.REST_ENDPOINT = getRestEndpoint();
      this.readDataFromAPI();
    },
    setup() {
      return {
        confirm: ref(false),
      };
    },
  };
  </script>