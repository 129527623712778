<template>
  <div class="q-pa-md q-gutter-sm">
    <q-breadcrumbs>
      <q-breadcrumbs-el icon="home" to="/" />
      <q-breadcrumbs-el label="Short Url" icon="code" />
      <q-breadcrumbs-el label="Links" icon="link" to="/short-url-links" />
      <q-breadcrumbs-el label="Add Link" icon="add" />
      <template v-slot:separator>
        <q-icon size="1.5em" name="chevron_right" color="primary" />
      </template>
    </q-breadcrumbs>

    <q-card class="sms-card">
      <q-form @submit.prevent="submitForm" class="qForm">
        <q-card-section>
          <div class="text-h6">Add ShortUrl Link</div>
        </q-card-section>

        <q-separator inset />

        <q-card-section class="column q-gutter-md">
          <span>Full link preview:</span>
          <span v-if="form.domain_id">{{ getFullLinkPreview }}</span>
        </q-card-section>

        <q-separator inset />

        <q-card-section class="column q-gutter-md">
          <span>Generated Short Link:</span>
          <span>{{ form.link || 'Generating...' }}</span>

          <q-select 
            outlined
            v-model="form.domain_id"
            :options="optionsDomains"
            label="Domain *"
            emit-value
            map-options
          />
          <span class="error-message">{{ formErrors.domain_id }}</span>

          <q-input
            v-model.trim="form.target_url"
            label="Target URL *"
            hint="Enter a valid URL (https)"
            :rules="[val => val.length <= 200 || 'Max 200 characters']"
            maxlength="200"
          />
          <span class="error-message">{{ formErrors.target_url }}</span>
        </q-card-section>

        <q-card-actions>
          <q-btn icon="add" color="altmedia-blue" label="Add Link" @click="submitForm" />
        </q-card-actions>

        <span
          class="msg-response"
          :class="{ success: sendResponseSuccess, error: !sendResponseSuccess }"
        >{{ sendResponse }}</span>
      </q-form>
    </q-card>
  </div>
</template>

<script>
import axios from 'axios';
import { getRestEndpoint } from '@/utils/utils';
import { handleClientTokenExpiration, handleTokenExpiration } from '@/utils/auth';

export default {
  name: 'ShortUrlLinksAdd',

  data() {
    return {
      form: {
        link: '',
        domain_id: null,
        target_url: '',
      },
      formErrors: {
        domain_id: '',
        target_url: '',
      },
      sendResponse: '',
      sendResponseSuccess: null,
      REST_ENDPOINT: '',
      optionsDomains: [],
    };
  },

  created() {
    handleClientTokenExpiration(this.$router);
    this.REST_ENDPOINT = getRestEndpoint();
    this.getDomains();
    this.generateUniqueLink();
  },

  computed: {
    selectedDomainName() {
      const selectedDomain = this.optionsDomains.find(domain => domain.value === this.form.domain_id);
      return selectedDomain ? selectedDomain.label : '';
    },
    getFullLinkPreview() {
      const selectedDomain = this.optionsDomains.find(domain => domain.value === this.form.domain_id);
      return selectedDomain ? `${selectedDomain.label}/${this.form.link}` : '';
    }
  },

  methods: {
    async generateUniqueLink() {
      const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
      let uniqueLink;

      for (let attempts = 0; attempts < 10; attempts++) {
        uniqueLink = Array(4)
          .fill('')
          .map(() => characters.charAt(Math.floor(Math.random() * characters.length)))
          .join('');

        const { data } = await axios.get(`${this.REST_ENDPOINT}short-url-links/exists/${uniqueLink}`, {
          withCredentials: true,
        });

        if (!data.exists) {
          this.form.link = uniqueLink;
          return uniqueLink;
        }
      }

      throw new Error('Failed to generate a unique short URL after multiple attempts.');
    },

    async submitForm() {
      this.sendResponse = '';
      this.validateForm();

      if (this.isFormValid()) {
        try {
          const formData = {
            link: this.form.link,
            domain_id: this.form.domain_id,
            domain_name: this.selectedDomainName,
            target_url: this.form.target_url,
          };

          await axios.post(`${this.REST_ENDPOINT}short-url-links`, formData, {
            withCredentials: true,
          });

          this.$router.push('/short-url-links');
        } catch (error) {
          if (error.response && error.response.status === 401 && error.response.data === 'Token expired') {
            handleTokenExpiration(this.$router);
          } else if (error.response && error.response.status === 400 && error.response.data && error.response.data.error) {
            this.sendResponse = error.response.data.error;
          }
        }
      }
    },

    async getDomains() {
      try {
        const response = await axios.get(`${this.REST_ENDPOINT}short-url-domains`, { withCredentials: true });
        
        this.optionsDomains = response.data.map((item) => ({
          id: item.id,
          label: item.domain_name,
          value: item.id,
        }));
        
        const defaultDomain = response.data.find(
          (item) => item.default === 1 && item.product_id === 0
        );

        if (defaultDomain) {
          this.form.domain_id = defaultDomain.id;
        }

      } catch (error) {
        if (error.response && error.response.status === 401 && error.response.data === 'Token expired') {
          handleTokenExpiration(this.$router);
        }
      }
    },

    validateForm() {
      const urlPattern = new RegExp(
        '^(https:\\/\\/)' +
        '(([a-zA-Z0-9_-]+\\.)+[a-zA-Z]{2,})' + 
        '(\\/[-a-zA-Z0-9@:%_+.~#?&//=]*)?$'
      );

      if (!this.form.domain_id) {
        this.formErrors.domain_id = 'Domain is required';
      } else {
        this.formErrors.domain_id = '';
      }

      if (!this.form.target_url) {
        this.formErrors.target_url = 'Target URL is required';
      } else if (!urlPattern.test(this.form.target_url)) {
        this.formErrors.target_url = 'Please enter a valid URL';
      } else if (this.form.target_url.length > 200) {
        this.formErrors.target_url = 'URL must be under 200 characters';
      } else {
        this.formErrors.target_url = '';
      }
    },

    isFormValid() {
      return Object.values(this.formErrors).every((error) => !error);
    },
  },
};
</script>

<style lang="scss">
.sms-card {
  width: 812px !important;
  margin: 10px 20px;
}

.msg-response {
  width: 100%;
  display: block;
  margin-top: 25px;
  margin-left: 10px;
  font-size: 1rem;
}

.msg-response.success {
  color: green;
}
.msg-response.error {
  color: red;
}

.error-message {
  color: red;
  font-size: 0.9em;
}
</style>
