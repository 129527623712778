<template>

  <div class="q-pa-md q-gutter-sm">
    <q-breadcrumbs>
      <q-breadcrumbs-el icon="home" to="/" />
      <q-breadcrumbs-el label="Short Url" icon="code" />
      <q-breadcrumbs-el label="Links" icon="link" />
      <template v-slot:separator>
        <q-icon size="1.5em" name="chevron_right" color="primary" />
      </template>
    </q-breadcrumbs>
  </div>

  <div class="q-pa-md">
    <q-table
      title="Short Url Links"
      :rows="filteredShortUrlLinksItems"
      :columns="columns"
      row-key="id"
      :sort-by="'id'"
      :loading="loading"
      :pagination="{ rowsPerPage: 20 }"
      color="primary"
      :filter="filter"
    >

    <template v-slot:body-cell-actions="scope">
        <q-td :props="scope">
          <q-btn
            flat
            round
            icon="content_copy"
            color="positive"  
            @click="copyUrl(scope.row.target_url)"  
          >
            <q-tooltip class="bg-positive">Copy Target Url</q-tooltip>
          </q-btn>


          <q-btn
            flat
            round
            icon="sync_disabled"
            :color="scope.row.disabled === 1 ? 'grey' : 'negative'"
            @click="archiveItem(scope.row.id, scope.row.link)"
            :disabled="scope.row.disabled === 1"
          >
            <q-tooltip class="bg-negative">Archive this link</q-tooltip>
          </q-btn>
        </q-td>
      </template>

      <template v-slot:top-left>
        <span class="q-gutter-sm">
          <q-radio v-model="status" @click="filterClick('all')" val="all" label="All" />
          <q-radio v-model="status" @click="filterClick('0')" val="0" label="Active" />
          <q-radio v-model="status" @click="filterClick('1')" val="1" label="Archived" />
        </span>

        <q-btn class="q-ml-lg" color="positive" icon="add" label="Add link" to="/short-url-links-add" />
        <q-btn
          class="q-ml-lg"
          color="altmedia-blue"
          icon="archive"
          label="Export"
          no-caps
          @click="exportTableCsv"
        />
        <q-space />
      </template>

      <template v-slot:top-right>
        <q-input dense debounce="300" color="primary" v-model="filter">
          <template v-slot:append>
            <q-icon name="search" />
          </template>
        </q-input>
      </template>
      
    </q-table>
  </div>
</template>

<script>
import { getRestEndpoint, exportTable } from '@/utils/utils';
import moment from 'moment';
import { handleClientTokenExpiration, handleTokenExpiration } from '@/utils/auth';
import axios from 'axios';



export default {
  name: 'ShortUrlLinks',
  data() {
    return {
      REST_ENDPOINT: '',
      loading: false,
      filter: '',
      status: 'all',
      shortUrlLinksItems: [],
      filteredShortUrlLinksItems: [],
      confirm: false,
      columns: [
        { name: 'link', align: 'left', label: 'Link', field: 'link', sortable: true, style: 'max-width: 80px; white-space: nowrap; overflow: hidden; text-overflow: ellipsis;' },
        { name: 'domain', align: 'left', label: 'Domain', field: 'domain_name', sortable: true, style: 'max-width: 80px; white-space: nowrap; overflow: hidden; text-overflow: ellipsis;' },
        { name: 'disabled', align: 'left', label: 'Archived', field: 'disabled', sortable: true, style: 'width: 40px;'},
        { name: 'fulllink', align: 'left', label: 'Full Link', field: 'fulllink', sortable: true },
        { name: 'target', align: 'left', label: 'Target Url', field: 'target_url', sortable: true, style: 'max-width: 80px; white-space: nowrap; overflow: hidden; text-overflow: ellipsis;' },
        { name: 'created_at', align: 'center', label: 'Created', field: 'created_at', sortable: true, format: (val) => moment(val).fromNow() },
        { name: 'actions', align: 'center', label: 'Actions', sortable: false, style: 'width: 200px' },
      ],
    };
  },
  created() {
    handleClientTokenExpiration(this.$router);
  },
  methods: {

    copyUrl(url) {
      if (navigator.clipboard) {
        navigator.clipboard.writeText(url)
          .then(() => {
            
          })
          .catch(() => {
            
          });
      }
    },
  
    exportTableCsv() {
      const columnsWithoutActions = [...this.columns];
      columnsWithoutActions.pop();
      exportTable(columnsWithoutActions, this.shortUrlLinksItems, 'short_url_links_');
    },
    filterClick(status) {
      this.status = status; 
      if (status === 'all') {
        this.filteredShortUrlLinksItems = this.shortUrlLinksItems;        
      } else {
        const isDisabled = status === '1'; 
        this.filteredShortUrlLinksItems = this.shortUrlLinksItems.filter((item) => item.disabled === (isDisabled ? 1 : 0));
      }
    },
    readDataFromAPI() {
      this.loading = true;
      
      axios
        .get(`${this.REST_ENDPOINT}short-url-links`, { withCredentials: true })
        .then((response) => {
          this.loading = false;
          this.shortUrlLinksItems = response.data.map((item) => ({
            ...item,
            fulllink: `${item.domain_name}/${item.link}`,
          })).sort((a, b) => b.id - a.id);
          
          this.filterClick(this.status); 
        })
        .catch((error) => {
          this.loading = false;
          if (error.response && error.response.status === 401 && error.response.data === 'Token expired') {
            handleTokenExpiration(this.$router);
          }
        });
    },

    async archiveItem(id, link) {
      if (confirm('Are you sure you want to archive this link?')) {
        try {
          await axios.delete(`${this.REST_ENDPOINT}short-url-links/${id}`, {
            data: { link: link },
            withCredentials: true,
          });

          await this.readDataFromAPI();

          this.filterClick(this.status);
        } catch (error) {
          if (error.response && error.response.status === 401 && error.response.data === 'Token expired') {
            handleTokenExpiration(this.$router);
          }
        }
      }
    }

  },
  mounted() {
    this.REST_ENDPOINT = getRestEndpoint();
    this.readDataFromAPI();
  },
};
</script>

<style lang="scss">
  
</style>
