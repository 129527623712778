<template>
  <div class="q-pa-md q-gutter-sm">
    <q-breadcrumbs>
      <q-breadcrumbs-el icon="home" to="/" />
      <q-breadcrumbs-el label="Short Url" icon="code" />
      <q-breadcrumbs-el label="Domains" icon="website" to="/short-url-domains" />
      <q-breadcrumbs-el label="Add Domain" icon="add" />
      <template v-slot:separator>
        <q-icon size="1.5em" name="chevron_right" color="primary" />
      </template>
    </q-breadcrumbs>
  </div>

  <q-card class="sms-card">
    <q-form @submit.prevent="submitForm" class="qForm">
      <q-card-section>
        <div class="text-h6">Add Domain</div>
      </q-card-section>

      <q-separator inset />

      <q-card-section class="column q-gutter-md">
        <q-input
          v-model.trim="form.name"
          label="Domain Name *"
          hint="Enter a valid URL (https)"
        />
        <span class="error-message">{{ formErrors.name }}</span>
      </q-card-section>

      <q-card-actions>
        <q-btn
          icon="add"
          color="altmedia-blue"
          label="Add Domain"
          @click="submitForm()"
        />
      </q-card-actions>

      <span
        class="msg-response"
        :class="{ success: sendResponseSuccess, error: !sendResponseSuccess }"
        >{{ sendResponse }}</span
      >
    </q-form>
  </q-card>
</template>

<script>
import { getRestEndpoint } from '@/utils/utils';
import { handleClientTokenExpiration, handleTokenExpiration } from '@/utils/auth';
import axios from 'axios';

export default {
  name: 'ShortUrlDomainsAdd',

  data() {
    return {
      form: {
        name: '',
      },

      formErrors: {
        name: '',
      },
      sendResponse: '',
      sendResponseSuccess: null,
      existingDomains: [],
      REST_ENDPOINT: '',
    };
  },
  
  methods: {
    async fetchExistingDomains() {

      try {
        const response = await axios.get(`${this.REST_ENDPOINT}short-url-domains`, {
          withCredentials: true,
        });
        this.existingDomains = response.data.map(domain => domain.domain_name);
      } catch (error) {
        console.error('Error fetching existing domains:', error);
      }
    },

    async submitForm() {
      this.sendResponse = '';
      this.validateForm();

      if (this.isFormValid()) {
        if (this.existingDomains.includes(this.form.name)) {
          this.formErrors.name = 'This domain already exists.';
          return;
        }

        try {
          const formData = {
            name: this.form.name,
          };

          await axios.post(`${this.REST_ENDPOINT}short-url-domains`, formData, {
            withCredentials: true,
          });

          this.$router.push('/short-url-domains');
        } catch (error) {
          if (error.response && error.response.status === 400 && error.response.data.errors) {
            this.handleValidationErrors(error.response.data.errors);
          } else if (error.response && error.response.status === 401 && error.response.data === 'Token expired') {
            handleTokenExpiration(this.$router);
          }
        }
      }
    },

    handleValidationErrors(errors) {
      errors.forEach((err) => {
        if (err.path === 'name') {
          this.formErrors.name = err.msg;
        }
      });
    },

    validateForm() {
      const urlPattern = new RegExp(
        '^(https:\\/\\/)' +
        '(www\\.)?' +                
        '([a-zA-Z0-9-]+)' +          
        '\\.[a-zA-Z]{2,}$'           
      );

      if (!this.form.name) {
        this.formErrors.name = 'Domain name field is required';
      } else if (!urlPattern.test(this.form.name)) {
        this.formErrors.name = 'Please enter a valid domain name without subdomains or paths';
      } else if (this.form.name.length > 255) {
        this.formErrors.name = 'Domain name must be under 255 characters';
      } else {
        this.formErrors.name = '';
      }
    },

    isFormValid() {
      return Object.values(this.formErrors).every((error) => !error);
    },
  },

  created() {
    handleClientTokenExpiration(this.$router);

    this.REST_ENDPOINT = getRestEndpoint();
    
    if (this.REST_ENDPOINT) {
      this.fetchExistingDomains();
    }
  },

};
</script>


<style lang="scss">
.sms-card {
  width: 812px !important;
  margin: 10px 20px;
}

.msg-response {
  width: 100%;
  display: block;
  margin-top: 25px;
  margin-left: 10px;
  font-size: 1rem;
}

.msg-response.success {
  color: green;
}
.msg-response.error {
  color: red;
}

.error-message {
  color: red;
  font-size: 0.9em;
}
</style>