<template>

  <div class="q-pa-md q-gutter-sm">
    <q-breadcrumbs>
        <q-breadcrumbs-el icon="home" to="/" />
        <q-breadcrumbs-el label="Reports" icon="code"  />
        <q-breadcrumbs-el label="Campaigns" icon="campaign" to="/reports-campaigns" />
        <template v-slot:separator>
          <q-icon size="1.5em" name="chevron_right" color="primary" />
        </template>
        <q-breadcrumbs-el v-if="contactGroupId > 0" :label="`Campaigns for contact group ID ${contactGroupId} - ${contactGroupName}`" />
      </q-breadcrumbs>
  </div>

  <div class="q-pa-md">
    <q-table
      title = "Contacts"
      :rows = "campaignItems"
      :columns = "columns"
      row-key = "id"
      :sort-by="'id'"
      :loading = "loading"
      :pagination="{ rowsPerPage: 20 }"
      color = "primary"
      :filter="filter"
    >

      <template v-slot:body-cell-name="scope">
        <q-td :props="scope">
          <router-link class="camp-details" :to="'/reports-campaigns/campaign/' + scope.row.id">
            <span :class="{'text-grey': scope.row.disabled === 1}">
              {{ scope.row.name }}
            </span>
          </router-link>
        </q-td>
      </template>

      <template v-slot:body-cell="{ row, col }">
      <q-td>
        <span :class="{'text-grey': row.disabled === 1}">
          {{ getField(row, col) }}
        </span>
      </q-td>
    </template>

      <template v-slot:body-cell-actions="scope">

          <q-td :props="scope">
            <q-btn
              flat
              round
              icon="sync_disabled"
              :color="scope.row.disabled === 1 ? 'grey' : 'negative'"
              :disable="scope.row.disabled === 1"
              @click="disableItem(scope.row.id)"
            >
            <q-tooltip class="bg-negative">Disable this campaign</q-tooltip>
            </q-btn>

        </q-td>
      </template>

      <template v-slot:loading>
        <q-inner-loading showing color="primary" />
      </template>
      
      <template v-slot:top-left>

        <span class="q-gutter-sm">
          <q-radio v-model="disabled" @click="this.disableFilterClick()" val="filterDisabled" label="All" />
          <q-radio v-model="disabled" @click="this.disableFilterClick(0)" val="0" label="Enabled" />
          <q-radio v-model="disabled" @click="this.disableFilterClick(1)" val="1" label="Disabled" />
        </span>
        
        <q-btn
          class="q-ml-lg"
          color="altmedia-blue"
          icon="archive"
          label="Export"
          no-caps
          @click="exportTableCsv"
        />
        <q-space />
      </template>

      <template v-slot:top-right>
        <q-input  dense debounce="300" color="primary" v-model="filter">
          <template v-slot:append>
            <q-icon name="search" />
          </template>
        </q-input>
      </template>
      
      </q-table>
  </div>
</template>
  
<script>
  import { getRestEndpoint, exportTable } from '@/utils/utils'
  import moment from 'moment';
  import axios from "axios";
  import { ref } from 'vue'
  import { handleClientTokenExpiration, handleTokenExpiration } from '@/utils/auth';

  export default {
    name: 'CampaignsList',
    data() {
      return {
        REST_ENDPOINT: '',
        loading: false,
        filter: '',
        campaignItems: [],
        disabled: 'filterDisabled',
        campaignItemsFiltered: [],
        contactGroupId: 0,
        contactGroupName: '',
        status: '',
        columns: [
          { name: 'name', align: 'left', label: 'Name', field: 'name', sortable: true, },
          { name: 'contact_group_count', align: 'left', label: 'Contact Groups No', field: 'contact_group_count', sortable: true, },
          { name: 'execution_count', align: 'left', label: 'Execution No', field: 'execution_count', sortable: true, },
          { name: 'created_at', align: 'center', label: 'Created', field: 'created_at', sortable: true, format: (val) => moment(val).fromNow() },
          { name: 'actions', align: 'center', label: 'Actions', sortable: false, style: 'width: 150px' },
        ]
      }
    },
    created() {
      handleClientTokenExpiration(this.$router);
    },
    methods: {
      getField(row, col) {
      if (col.field === 'created_at') {
        return moment(row[col.field]).fromNow();
      } else {
        return row[col.field];
      }
    },
      formatDate(date) {
        return moment(date).fromNow();
      },
      exportTableCsv() {
        const columnsWithoutActions = [...this.columns];
        columnsWithoutActions.pop();
        exportTable(columnsWithoutActions, this.campaignItems, 'campaigns_')
      },
      disableFilterClick(status) {
        this.status = status;
        
        if (status === undefined) {
          this.campaignItems = this.campaignItemsFiltered;
        } else {
          this.campaignItems = this.campaignItemsFiltered.filter((item) => item.disabled === status);
        }
      },
      
      async readDataFromAPI() {
        this.loading = true;
        let apiURL = `${this.REST_ENDPOINT}campaign`;

        if (this.contactGroupId > 0) {
          apiURL += `/contact-group/${this.contactGroupId}`;
          try {
            const contactGroupResponse = await axios.get(
              `${this.REST_ENDPOINT}contact-groups/details/${this.contactGroupId}`,
              { withCredentials: true }
            );
            this.contactGroupName = contactGroupResponse.data;
            await this.fetchCampaignData(apiURL);
          } catch (error) {
            console.error('Error fetching contact group data:', error);
            this.loading = false; 
          }
        } else {
          await this.fetchCampaignData(apiURL);
        }
      },

      async fetchCampaignData(apiURL) {
        try {
          const response = await axios.get(apiURL, { withCredentials: true });
          this.loading = false;
          this.campaignItemsFiltered = response.data;
          this.campaignItems = response.data.sort((a, b) => b.id - a.id);
        } catch (error) {
          console.error('Error fetching campaign data:', error);
          if (error.response && error.response.status === 401 && error.response.data === 'Token expired') {
            handleTokenExpiration(this.$router);
          }
          this.loading = false;
        }
      },

      async disableItem(id) {
        if (confirm('Are you sure you want to disable this campaign?')) {
          try {
            await axios.put(`${this.REST_ENDPOINT}campaign/${id}`, {}, { withCredentials: true });
            
            this.campaignItems = [];
            this.campaignItemsFiltered = [];

            await this.readDataFromAPI();
            this.disableFilterClick(this.status);
          } catch (error) {
            if (error.response && error.response.status === 401 && error.response.data === 'Token expired') {
              handleTokenExpiration(this.$router);
            }
          }
        }
      }

    },
    mounted() {
      this.REST_ENDPOINT = getRestEndpoint();
      this.contactGroupId = this.$route.params.contactGroupId;
      this.readDataFromAPI();
    },
    setup () {
      return {
        confirm: ref(false),
      }
    }
  }
</script>
  
<style lang="scss">
  .camp-details {
    text-decoration: none;
    font-family: Montserrat, Helvetica, Arial, serif;
    color: #027be3;
    font-weight: 400;
    font-size: 14px;
  }
</style>