<template>

  <q-dialog v-model="confirm" persistent>
    <q-card>
      <q-card-section class="row items-center">
        <q-avatar icon="delete" color="negative" text-color="white" />
        <span class="q-ml-sm">Are you sure?</span>
      </q-card-section>

      <q-card-actions align="right">
        <q-btn flat label="Cancel" color="negative" v-close-popup />
        <q-btn flat label="Delete" color="positive" v-close-popup @click="removeSelectedItems" />
      </q-card-actions>
    </q-card>
  </q-dialog>

  <div class="q-pa-md q-gutter-sm">
    <q-breadcrumbs>
      <q-breadcrumbs-el icon="home" to="/" />
      <q-breadcrumbs-el label="Contact Groups" icon="groups" to="/contact-groups" />
      <q-breadcrumbs-el :label="contactGroupName" icon="person" />
      <template v-slot:separator>
        <q-icon
          size="1.5em"
          name="chevron_right"
          color="primary"
        />
      </template>
    </q-breadcrumbs>
  </div>

  <div class="q-pa-md">
    <q-table
      title = "Contacts"
      :rows = "contactsItems"
      :columns = "columns"
      row-key = "id"
      selection = "multiple"
      v-model:selected = "selected"
      :loading = "loading"
      :pagination="{ rowsPerPage: 20 }"
      color = "primary"
      :filter="filter"
    >

      <template v-slot:body-cell-actions="scope">
        <q-td :props="scope">
          <q-btn flat round icon="edit" color="positive" :to="'/contacts-edit/' + scope.row.id">
            <q-tooltip class="bg-positive">Edit this contact</q-tooltip>
          </q-btn>
          <q-btn flat round icon="delete" color="negative" @click=removeItem(scope.row.id)>
            <q-tooltip class="bg-negative">Delete this contact</q-tooltip>
          </q-btn>
        </q-td>
      </template>

      <template v-slot:loading>
        <q-inner-loading showing color="primary" />
      </template>
      
      <template v-slot:top-left>
        <q-btn color="negative" icon="delete" :disable="this.selected.length == 0" label="Remove selected" @click="confirm = true" />
        <q-btn color="positive" icon="add" class="q-ml-lg" label="Add contact" :to="`/contacts-add/${this.contactGroupId}`" />
        <q-btn color="purple" icon="unarchive" class="q-ml-lg" label="Import contacts" :to="`/contacts-import/${this.contactGroupId}`" />
        <q-btn
          class="q-ml-lg"
          color="altmedia-blue"
          icon="archive"
          label="Export"
          no-caps
          @click="exportTableCsv"
        />
        <q-space />
      </template>

      <template v-slot:top-right>
        <q-input  dense debounce="300" color="primary" v-model="filter">
          <template v-slot:append>
            <q-icon name="search" />
          </template>
        </q-input>
      </template>
      
      </q-table>
  </div>
</template>
  
<script>
  import { getRestEndpoint, exportTable } from '@/utils/utils'
  import { handleClientTokenExpiration, handleTokenExpiration } from '@/utils/auth';
  import axios from "axios";
  import { ref } from 'vue'

  export default {
    name: 'ContactGroupDetails',
    data() {
      return {
        contactGroupId: null,
        contactGroupName: null,
        REST_ENDPOINT: '',
        loading: false,
        filter: '',
        selected: [],
        contactsItems: [],
        contactGroupMembersIds: [],
        columns: [
          { name: 'first_name', align: 'left', label: 'First Name', field: 'first_name', sortable: true, },
          { name: 'last_name', align: 'center', label: 'Last Name', field: 'last_name', sortable: true, },
          { name: 'phone', align: 'center', label: 'Phone', field: 'phone', sortable: true, },
          { name: 'actions', align: 'center', label: 'Actions', sortable: false, style: 'width: 150px' },
        ]
      }
    },
    created() {
      handleClientTokenExpiration(this.$router);
    },
    methods: {
      getContactGroupName() {
        axios
          .get(`${this.REST_ENDPOINT}contact-groups/details/${this.contactGroupId}`, { withCredentials: true })
          .then((response) => {
            this.contactGroupName = response.data;
          })
          .catch((error) => {
            if (error.response && error.response.status === 401 && error.response.data === 'Token expired') {
              handleTokenExpiration(this.$router);
            }
          });
      },

      exportTableCsv() {
        const columnsWithoutActions = [...this.columns];
        columnsWithoutActions.pop();
        exportTable(columnsWithoutActions, this.contactsItems, 'contacts_')
      },
      readDataFromAPI() {
        this.loading = true;
        axios
          .get(`${this.REST_ENDPOINT}contact/all/${this.contactGroupId}`, { withCredentials: true })
          .then((response) => {
            this.loading = false;
            this.contactsItems = response.data;
          })
          .catch((error) => {
            if (error.response && error.response.status === 401 && error.response.data === 'Token expired') {
              handleTokenExpiration(this.$router);
            }
          });
      },

      removeItem(id) {
        if (confirm('Are you sure?')) {
          axios
            .delete(`${this.REST_ENDPOINT}contact/${this.contactGroupId}/${id}`, { withCredentials: true })
            .then(() => {
              this.contactsItems = this.contactsItems.filter((item) => item.id !== id);
            })
            .catch((error) => {
              if (error.response && error.response.status === 401 && error.response.data === 'Token expired') {
                handleTokenExpiration(this.$router);
              } 
            });            
        }
      },

      async removeSelectedItems() {
        try {
          const deletePromises = this.selected.map(async (selectedItem) => {
            const selID = selectedItem.id;
            await axios.delete(`${this.REST_ENDPOINT}contact/${this.contactGroupId}/${selID}`, { withCredentials: true });
          });

          await Promise.all(deletePromises);

          this.contactsItems = [];
          await this.readDataFromAPI();
          this.loading = false;
        } catch (error) {
          if (error.response && error.response.status === 401 && error.response.data === 'Token expired') {
            handleTokenExpiration(this.$router);
          }

        }
      },

    },
    mounted() {
      this.contactGroupId = this.$route.params.id;
      this.REST_ENDPOINT = getRestEndpoint();
      this.getContactGroupName();
      // this.getContactGroupMembersIds();
      this.readDataFromAPI();
    },
    setup () {
      return {
        confirm: ref(false),
      }
    }
  }
</script>
  