<template>

  <div class="q-pa-md q-gutter-sm">
    <q-breadcrumbs>
      <q-breadcrumbs-el icon="home" to="/" />
      <q-breadcrumbs-el label="Short Url" icon="code"  />
      <q-breadcrumbs-el label="Domains" icon="website" />
      <template v-slot:separator>
        <q-icon
          size="1.5em"
          name="chevron_right"
          color="primary"
        />
      </template>
    </q-breadcrumbs>
  </div>

  <div class="q-pa-md">
    <q-banner dense color="negative" icon="warning" class="banner-text">
      Default domain and domains with links cannot be edited / deleted.
    </q-banner>
  </div>



  <div class="q-pa-md">
    <q-table
      title = "Short Url Domains"
      :rows = "shortUrlDomainsItems"
      :columns = "columns"
      row-key = "id"
      :sort-by="'id'"
      :loading = "loading"
      :pagination="{ rowsPerPage: 20 }"
      color = "primary"
      :filter="filter"
    >

      <template v-slot:body-cell-actions="scope">
        <q-td :props="scope">

          <q-btn 
            flat 
            round 
            icon="edit" 
            :color="scope.row.default === 1 || scope.row.links > 0 ? 'grey' : 'positive'"
            :disable="scope.row.default === 1 || scope.row.links > 0"
            :to="'/short-url-domains-edit/' + scope.row.id">
            <q-tooltip class="bg-positive">Edit this domain</q-tooltip>
          </q-btn>

          <q-btn 
            flat
            round 
            icon="delete" 
            :color="scope.row.default === 1 || scope.row.links > 0 ? 'grey' : 'negative'"
            :disable="scope.row.default === 1 || scope.row.links > 0"
            @click="removeItem(scope.row.id)">
            <q-tooltip class="bg-negative">Delete this domain</q-tooltip>
          </q-btn>

        </q-td>
        <q-space /><br >
      </template>
      
      <template v-slot:top-left>
        <q-btn color="positive" icon="add" :disable="this.shortUrlDomainsItems.length === 2"  label="Add domain" to="/short-url-domains-add" />
        <q-space />
      </template>

      <template v-slot:top-right>
        <q-input  dense debounce="300" color="primary" v-model="filter">
          <template v-slot:append>
            <q-icon name="search" />
          </template>
        </q-input>
      </template>

    </q-table>
  </div>
</template>
  
<script>
  import { getRestEndpoint } from '@/utils/utils'
  import moment from 'moment';
  import { handleClientTokenExpiration, handleTokenExpiration } from '@/utils/auth';
  import axios from "axios";
  import { ref } from 'vue'

  export default {
    name: 'ShortUrlDomains',
    data() {
      return {
        REST_ENDPOINT: '',
        loading: false,
        filter: '',
        shortUrlDomainsItems: [],
        columns: [
          { name: 'name', align: 'left', label: 'Name', field: 'domain_name', sortable: true },
          { name: 'links', align: 'left', label: 'Links', field: 'links', sortable: true, style: 'width: 40px;'},
          { name: 'created_at', align: 'center', label: 'Created', field: 'created_at', sortable: true, format: (val) => moment(val).fromNow() },
          { name: 'actions', align: 'center', label: 'Actions', sortable: false, style: 'width: 200px', },
        ]
      }
    },
    created() {
      handleClientTokenExpiration(this.$router);
    },
    methods: {
      getField(row, col) {
        if (col.field === 'created_at') {
          return moment(row[col.field]).fromNow();
        } else {
          return row[col.field];
        }
      },
      readDataFromAPI() {
        this.loading = true;
        axios
          .get(`${this.REST_ENDPOINT}short-url-domains`, { withCredentials: true })
          .then((response) => {
            this.loading = false;
            this.shortUrlDomainsItems = response.data;
          })
          .catch((error) => {
            if (error.response && error.response.status === 401 && error.response.data === 'Token expired') {
              handleTokenExpiration(this.$router);
            }
          });
      },
      removeItem(id) {
        if (confirm('Are you sure?')) {
          axios
            .delete(`${this.REST_ENDPOINT}short-url-domains/${id}`, { withCredentials: true })
            .then(() => {
              this.shortUrlDomainsItems = this.shortUrlDomainsItems.filter((item) => item.id !== id);
            })
            .catch((error) => {
              if (error.response && error.response.status === 401 && error.response.data === 'Token expired') {
                handleTokenExpiration(this.$router);
              }
            });
        }
      }
      
    },
    mounted() {
      this.REST_ENDPOINT = getRestEndpoint();
      this.readDataFromAPI();
    },
    setup () {
      return {
        confirm: ref(false),
      }
    }
  }
</script>
  
<style lang="scss">
  .banner-text {
    color: red;
  }
</style>
